import auth0 from "auth0-js";
import { EventEmitter } from "events";
import authConfig from "../../auth_config.json";

const webAuth = new auth0.WebAuth({
  domain: authConfig.domain,
  redirectUri: `${window.location.origin}/callback`,
  audience: authConfig.audience,
  clientID: authConfig.clientId,
  responseType: "token id_token",
  scope: "openid profile email roles"
});

const localStorageKey = "loggedIn";
const loginEvent = "loginEvent";

class AuthService extends EventEmitter {
  idToken = null;
  accessToken = null;
  profile = null;
  tokenExpiry = null;
  accessTokenExpiry = null;
  authenticated = false;
  admin = false;
  professor = false;

  login(customState) {
    webAuth.authorize({
      appState: customState
    });
  }

  logOut(path = "") {
    localStorage.removeItem(localStorageKey);

    this.idToken = null;
    this.accessToken = null;
    this.tokenExpiry = null;
    this.accessTokenExpiry = null;
    this.profile = null;
    this.admin = false;
    this.professor = false;
    this.authenticated = false;

    webAuth.logout({
      returnTo: `${window.location.origin}${process.env.BASE_URL}${path}`
    });

    this.emit(loginEvent, { loggedIn: false });
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      webAuth.parseHash((err, authResult) => {
        if (err) {
          this.emit(loginEvent, {
            loggedIn: false,
            error: err,
            errorMsg: err.statusText
          });
          reject(err);
        } else {
          this.localLogin(authResult);
          resolve(authResult.idToken);
        }
      });
    });
  }

  isAuthenticated() {
    return Date.now() < this.tokenExpiry && localStorage.getItem(localStorageKey) === "true";
  }

  isIdTokenValid() {
    return this.idToken && this.tokenExpiry && Date.now() < this.tokenExpiry;
  }

  isAccessTokenValid() {
    return this.accessToken && this.accessTokenExpiry && Date.now() < this.accessTokenExpiry;
  }

  isAdmin() {
    return this.admin;
  }

  isProfessor() {
    return this.professor;
  }

  hasAdminRole() {
    if (this.profile.roles != null) {
      return authConfig.adminRoles.some(role => this.profile.roles.includes(role));
    } else {
      return false;
    }
  }

  hasProfessorRole() {
    if (this.profile.roles != null) {
      return authConfig.professorRoles.some(role => this.profile.roles.includes(role));
    } else {
      return false;
    }
  }

  hasAnyRequiredRole() {
    if (this.profile.roles != null) {
      return authConfig.userRoles.some(role => this.profile.roles.includes(role));
    } else {
      return false;
    }
  }

  getIdToken() {
    return new Promise((resolve, reject) => {
      if (this.isIdTokenValid()) {
        resolve(this.idToken);
      } else if (this.isAuthenticated()) {
        this.renewTokens().then(authResult => {
          resolve(authResult.idToken);
        }, reject);
      } else {
        resolve();
      }
    });
  }

  getAccessToken() {
    return new Promise((resolve, reject) => {
      if (this.isAccessTokenValid()) {
        resolve(this.accessToken);
      } else {
        this.renewTokens().then(authResult => {
          resolve(authResult.accessToken);
        }, reject);
      }
    });
  }

  localLogin(authResult) {
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    this.profile.roles = this.profile[`${authConfig.tokenNamespace}/roles`];
    this.accessToken = authResult.accessToken;
    this.admin = this.hasAdminRole();
    this.professor = this.hasProfessorRole();
    this.authenticated = true;

    // Convert the expiry time from seconds to milliseconds,
    // required by the Date constructor
    this.tokenExpiry = new Date(this.profile.exp * 1000);

    // Convert expiresIn to milliseconds and add the current time
    // (expiresIn is a relative timestamp, we want an absolute time)
    this.accessTokenExpiry = new Date(Date.now() + authResult.expiresIn * 1000);

    localStorage.setItem(localStorageKey, "true");

    this.emit(loginEvent, {
      loggedIn: this.authenticated,
      isAdmin: this.admin,
      isProfessor: this.professor,
      profile: this.profile,
      bearerToken: this.accessToken,
      state: authResult.appState || {}
    });
  }

  renewTokens() {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem(localStorageKey) !== "true") {
        reject("Not logged in");
      }

      webAuth.checkSession({}, (err, authResult) => {
        if (err) {
          reject(err);
        } else {
          this.localLogin(authResult);
          resolve(authResult);
        }
      });
    });
  }
}

const service = new AuthService();

service.setMaxListeners(5);

export default service;
