var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: { value: _vm.opened, app: "", clipped: _vm.clipped },
      on: { input: _vm.sideMenuClick }
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        _vm._l(_vm.menu, function(item, i) {
          return _c(
            "v-list-item",
            { key: i, attrs: { to: item.to } },
            [
              _c(
                "v-list-item-action",
                [
                  _c("v-icon", { domProps: { textContent: _vm._s(item.icon) } })
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", {
                    staticClass: "text-uppercase",
                    domProps: { textContent: _vm._s(item.title) }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }