const estadoCivil = [
  "",
  "SOLTEIRO (A)",
  "DIVORCIADO (A)",
  "SEPARADO (A) JUDICIALMENTE",
  "VIÚVO (A)",
  "CASADO (A) COM COMUNHÃO TOTAL DE BENS",
  "CASADO (A) COM SEPARAÇÃO DE BENS",
  "CASADO (A) COM COMUNHÃO PARCIAL DE BENS"
];

export default estadoCivil;
