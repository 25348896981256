import _ from "lodash";
import { createHelpers, getField } from "vuex-map-fields";
import agenciasCaixaEstadoCidade from "../../api/model/agencias-caixa-estado-cidade";
import estados from "../../api/model/estados-cidades";
import nacionalidades from "../../api/model/nacionalidades";
import grauInstrucoes from "../../api/model/grau-instrucoes";
import tiposDocumentos from "../../api/model/tipos-documentos";
import paises from "../../api/model/paises-gentilicos-google-maps";
import estadoCivil from "../../api/model/estado-civil";
import ocupacoesPorTipo from "../../api/model/ocupacoesPorTipo";
import tiposLogradouros from "../../api/model/tipos-logradouros";
import tiposImoveis1 from "../../api/model/tipos-imoveis1";
import tiposOcupacaoImovel from "../../api/model/tipos-ocupacao-imovel";
import tiposComprovantesRenda from "../../api/model/tipos-comprovantes-renda";
import tiposGarantia from "../../api/model/tipos-garantia";
import orgRec from "../../api/model/origens-recurso";

const state = {
  agenciasCaixaEstadoCidade: agenciasCaixaEstadoCidade,
  estadoCivilTipos: estadoCivil,
  estados: estados,
  fontes: ["", "FÍSICA", "JURÍDICA"],
  grauInstrucoes: grauInstrucoes,
  nacionalidades: nacionalidades,
  ocupacoesPorTipo: ocupacoesPorTipo,
  paises: paises,
  rendas: ["", "SEM RENDA", "COMPROVADA"],
  sexos: ["", "MASCULINO", "FEMININO"],
  simNao: ["", "SIM", "NÃO"],
  tiposComprovantesRenda: tiposComprovantesRenda,
  tiposDocumentos: tiposDocumentos,
  tiposImoveis1: tiposImoveis1,
  tiposLogradouros: tiposLogradouros,
  tiposOcupacaoImovel: tiposOcupacaoImovel,
  tiposGarantia: tiposGarantia,
  sistemasAmortizacao: ["", "SAC", "PRICE"],
  indexadores: ["TR", "IPCA"],
  origensRecursos: orgRec.origensRecursos
};

// getters
const getters = {
  getField,
  estados: state => {
    return state.estados.map(estado => {
      return { sigla: estado.sigla, nome: estado.nome };
    });
  },
  cidades: state => uf => {
    return _.map(
      _.get(
        state.estados.find(estado => estado.sigla === uf),
        "cidades"
      ),
      value => _.toUpper(value)
    );
  },
  paises: state => (includeBR = false) => {
    return state.paises
      .filter(value => includeBR || value.sigla !== "BR")
      .map(pais => {
        return { value: _.toUpper(pais.nome_pais), text: _.toUpper(pais.nome_pais) };
      });
  },
  agenciaCaixa: state => (uf, cidade) => {
    const agencias = _.get(state.agenciasCaixaEstadoCidade, [_.toUpper(uf), _.toUpper(cidade)], []);
    return _.map(agencias, value => _.toUpper(value)).sort();
  },
  tipoDocumentoSubTipo: state => tipoDocumento => {
    if (tipoDocumento) {
      return state.tiposDocumentos.find(tipo => tipo.value === tipoDocumento).subTipos.map(subTipo => subTipo.value);
    } else {
      return [];
    }
  },
  tipoDocumentoSubTipoOrgaoEmissores: state => (tipoDocumento, subTipo) => {
    if (tipoDocumento) {
      if (tipoDocumento === "DOCUMENTO DE IDENTIFICAÇÃO") {
        return subTipo
          ? state.tiposDocumentos.find(tipo => tipo.value === tipoDocumento).subTipos.find(s => s.value === subTipo)
              .orgaoEmissores
          : [];
      } else {
        return state.tiposDocumentos.find(tipo => tipo.value === tipoDocumento).orgaoEmissores;
      }
    } else {
      return [];
    }
  },
  tiposOcupacoes: state => {
    return ["", ..._.keys(state.ocupacoesPorTipo)];
  },
  todasOcupacoes: state => {
    return _.sortedUniq([...state.ocupacoesPorTipo.FORMAL, ...state.ocupacoesPorTipo.INFORMAL].sort());
  },
  ocupacaoPorTipo: state => tipo => {
    if (tipo) {
      return ["", ..._.get(state.ocupacoesPorTipo, tipo)];
    } else {
      return [];
    }
  },
  separateTypeAndAddress: state => address => {
    if (address) {
      const upperAddress = address.toUpperCase();

      const tipo = state.tiposLogradouros.find(tipo => tipo && _.includes(upperAddress, tipo));
      const finalAddress = _.replace(upperAddress, tipo, "");

      return { tipoLogradouro: tipo, logradouro: finalAddress };
    } else {
      return { tipoLogradouro: null, logradouro: null };
    }
  },
  // eslint-disable-next-line no-unused-vars
  modalidadePorOrigemRecurso: state => origemRecurso => {
    if (origemRecurso) {
      return orgRec.modalidadePorOrigRecurso(origemRecurso);
    }
  },
  // eslint-disable-next-line no-unused-vars
  produtoPorOrigRecursoAndModalidade: state => (origemRecurso, modalidade) => {
    if (origemRecurso && modalidade) {
      return orgRec.produtoPorOrigRecursoAndModalidade(origemRecurso, modalidade);
    }
  }
};

// mutations
const mutations = {};

// actions
const actions = {};

// EXPORTS
export const { mapFields: mapDadosGeraisFields } = createHelpers({
  getterType: "dadosGerais/getField"
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
