import _ from "lodash";

// noinspection JSNonASCIINames,NonAsciiCharacters
const origensRecursosMap = {
  CAIXA: {
    modalidades: {
      "Aquisição de imóvel novo": { produtos: null },
      "Aquisição de imóvel usado": { produtos: null },
      "Aquisição de imóvel na planta": { produtos: null },
      "Aquisição de lote urbanizado individual": { produtos: null },
      "Aquisição de lote urbanizado - em produção": { produtos: null },
      "Construção/aq.terreno e const. Individual": { produtos: null },
      "Reforma e/ou ampliação.": { produtos: null },
      "Transferência de dívida": { produtos: null },
      "Imóvel de propriedade da CAIXA": { produtos: null },
      "Crédito Aporte Caixa (SDE)": { produtos: null }
    }
  },
  "PRÓ-COTISTA": {
    modalidades: {
      "Aquisição de imóvel novo": {
        produtos: [
          "Aquisicao de Imovel Residencial Novo - Recursos FGTS ¿¿¿ Pro-Cotista",
          "PRO-COTISTA - FGTS - (V.V. at¿ os limites FGTS/MUNICIPIO) - ALOCA¿¿O DE RECURSOS - PF.",
          "PRO-COTISTA - FGTS - (V.V. limites FGTS/MUNICIPIO AT¿ 500 MIL) - ALOCA¿¿O DE RECURSOS - PF.",
          "PRO-COTISTA - FGTS - (V.V. acima de 500 mil at¿ SFH) - ALOCA¿¿O DE RECURSOS - PF."
        ]
      },
      "Aquisição de imóvel usado": {
        produtos: ["Aquisicao de Imovel Residencial Usado - Recursos FGTS - Pro-Cotista"]
      },
      "Aquisição de imóvel na planta": {
        produtos: [
          "Aquisigco de Terreno e Construgco - Apoio PJ(SIPAH)",
          "Aquisigco de Terreno e Construgco no SFH - apoio s/PJ (SIPAH)",
          "(SIPAH) Alocação de Recursos - PF - Procotista",
          "FGTS - AQ TER CONST - PF - PR¿-COTISTA - (V.V. at¿ os limites FGTS/MUNICIPIO) - EMPREEND COM PJ",
          "FGTS - AQ TER CONST - PF - PR¿-COTISTA - (V.V. limites FGTS/MUNICIPIO AT¿ 500 MIL) - EMPREEND COM PJ",
          "FGTS - AQ TER CONST - PF - PR¿-COTISTA - (V.V. acima de 500 mil at¿ SFH) - EMPREEND COM PJ",
          "Aquisi¿¿o de Unidade Complementar - FGTS/Pr¿-Cotista - Pessoa F¿sica SEM PJ",
          "Aquisi¿¿o de Unidade Complementar - FGTS/Pr¿-Cotista - Pessoa F¿sica Limite FGTS/Munic¿pio  SEM PJ",
          "Aquisi¿¿o de Unidade Complementar - FGTS/Pr¿-Cotista - PF Limite FGTS/Munic¿pio at¿ 500 mil sem PJ",
          "Aquisi¿¿o de Unidade Complementar - FGTS/Pr¿-Cotista - Pessoa F¿sica Acima de 500 mil at¿ SFH SEM PJ",
          "FGTS - AQ TER CONST - PF - PR¿-COTISTA - (V.V. at¿ os limites FGTS/MUNICIPIO) - EMPREEND SEM PJ",
          "FGTS - AQ TER CONST - PF - PR¿-COTISTA - (V.V. limites FGTS/MUNICIPIO AT¿ 500 MIL) - EMPREEND SEM PJ",
          "FGTS - AQ TER CONST - PF - PR¿-COTISTA - (V.V. acima de 500 mil at¿ SFH) - EMPREEND SEM PJ",
          "Aq de Terreno e Construcao - PEC/MPE - PF - FGTS/Pro-Cotista - Res limite FGTS/Munic¿pio",
          "Aq de Terreno e Construcao - PEC/MPE - PF - FGTS/Pro-Cotista - Res Limite FGTS/Munic¿pio at¿ 500 Mil",
          "FGTS - PEC- AGREGACAO  PROCOTISTA -MPE-AV ACIMA DE 500 MIL AT¿ SFH",
          "FGTS - PEC- AGREGA¿¿O PROCOTISTA -AV ACIMA DE 500 MIL AT¿ SFH",
          "FGTS - PEC - PROCOTISTA - MPE DESLIGAMENTO-AV ACIMA DE 500 MIL AT¿ SFH",
          "Aq de UH - desligamento PEC/MPE - FGTS/Pr¿-Cotista - PF Limite FGTS/Munic¿pio",
          "Aq de UH - desligamento PEC/MPE - FGTS/Pr¿-Cotista - PF Limite FGTS/Munic¿pio at¿ 500 mil"
        ]
      },
      "Aquisição de terreno e construção": {
        produtos: [
          "(SIOPI) CCPROCOTISTA - Construcao em Terreno Proprio",
          "(SIOPI) CCPROCOTISTA - Aquisicao de Terreno e Construcao"
        ]
      },
      "Adjudicado CAIXA/EMGEA/LEILÃO": {
        produtos: null
      }
    }
  },
  FGTS: {
    modalidades: {
      "Aquisição de imóvel novo": {
        produtos: [
          "NPMCMV - Imovel Novo Individual",
          "NPMCMV - Alocacao de Recursos - Casa Paulista",
          "NPMCMV - Alocacao de Recursos",
          "Aquisicao de Imovel Residencial Novo - Recuros FGTS - PMCMV - Empregado CAIXA",
          "(SIPAH) Imovel novo",
          "(SIOPI) PMCMV - FGTS - Aquisicao de Imovel Residencial Novo - Nao Empregado"
        ]
      },
      "Aquisição de imóvel usado": {
        produtos: [
          "NPMCMV - Imovel Usado Individual"
        ]
      },
      "Aquisição de imóvel na planta": { 
        produtos: [
          "NPMCMV/FGTS PF - Associativo - Imovel na Planta - Construcao",
          "NPMCMV/FGTS PF - Associativo - Imovel na Planta - Construcao em Terreno Doado pelo Poder Publico",
          "NPMCMV/FGTS PF - Associativo - Imovel na Planta - Aquisicao de Terreno e Construcao",
          "NPMCMV/FGTS - PF - ASSOCIATIVO - IMOVEL NA PLANTA - CONSTRUCAO - EMPREEND. 100%",
          "NPMCMV - Unidade Vinculada PF - Empreendimento - Parcerias",
          "NPMCMV - Unidade Vinculada PF - Empreendimento - Com Fin. PJ - Parcerias",
          "NPMCMV - Unidade Vinculada PF - Empreendimento - Com Fin. PJ",
          "NPMCMV - Unidade Vinculada PF - Empreendimento",
          "NPMCMV - Unidade Complementar PF - Empreendimento - Terreno Doado - Casa Paulista",
          "NPMCMV - Unidade Complementar PF - Empreendimento - Terreno Doado - Casa Facil PR",
          "NPMCMV - Unid Vinculada PF - PEC",
          "NPMCMV - Unid Vinculada PF - Empreendimento - Terreno Doado - Casa Paulista",
          "NPMCMV - Unid Vinculada PF - Empreendimento - Terreno Doado",
          "NPMCMV - Unid Vinculada PF - Empreendimento - Emendas - Com Fin. PJ",
          "NPMCMV - Unid Vinculada PF - Empreendimento - Emendas",
          "NPMCMV - Unid Vinculada PF - Empreendimento - Casa Paulista - Com Fin. PJ",
          "NPMCMV - Unid Vinculada PF - Empreendimento - Casa Paulista",
          "NPMCMV - Unid Vinculada PF - Empreendimento - Casa Facil PR - Com Fin. PJ",
          "NPMCMV - Unid Vinculada PF - Empreendimento - Casa Facil PR",
          "NPMCMV - Unid Complementar PF - PEC",
          "NPMCMV - Unid Complementar PF - Empreendimento - Emendas - Com Fin. PJ",
          "NPMCMV - Unid Complementar PF - Empreendimento - Emendas",
          "NPMCMV - Unid Complementar PF - Empreendimento - Com Fin. PJ",
          "NPMCMV - Unid Complementar PF - Empreendimento - Casa Paulista - Com Fin. PJ",
          "NPMCMV - Unid Complementar PF - Empreendimento - Casa Paulista",
          "NPMCMV - Unid Complementar PF - Empreendimento - Casa Facil PR - Com Fin. PJ",
          "NPMCMV - Unid Complementar PF - Empreendimento - Casa Facil PR",
          "NPMCMV - Unid Complementar PF - Empreendimento",
          "(SIPAH) Imovel na Planta"
        ] 
      },
      "Aquisição de terreno e construção": {
        produtos: [
          "NPMCMV - Terreno e Construcao Individual",
          "NPMCMV - Construcao Individual em Terreno Doado",
          "NPMCMV - Construcao Individual",
          "Construcao em Terreno Proprio - Recursos FGTS - PMCMV - N Empregado",
          "Construcao em Terreno Proprio - Recursos FGTS - PMCMV - Empregado CAIXA",
          "Aquisicao de Terreno e Construcao - Recursos FGTS - PMCMV - N Empregado",
          "Aquisicao de Terreno e Construcao - Recursos FGTS - PMCMV - Empregado CAIXA"
        ]
      },
      "Reforma e/ou ampliação": {
        produtos: [
          "NPMCMV - Reforma ou Melhoria - PCD",
          "NPMCMV - Reforma ou Melhoria"
        ]
      },
      "Adjudicado CAIXA/EMGEA/LEILÃO": { 
        produtos: [
          "NPMCMV - Imovel Arrematado em Leilao CAIXA - sem consolidacao da Propriedade",
          "NPMCMV - Imovel Adjudicado CAIXA"
        ] 
      },
      "Unidade residencial de empreendimento Faixa 1,5": {
        produtos: [
          "MCMV  UNID VINCULADA/UNID COMPLEMENTAR FX1,5  SIOPI/SIPAH"
        ]
      },
    }
  },
  SBPE: {
    modalidades: {
      "Aquisição de imóvel residencial novo - Individual/Alocação de Recursos": {
        produtos: [
          "(SIOPI) Imovel Novo - SFH - Nao empregado CAIXA",
          "(SIOPI) Imovel Novo - SFH - Empregado CAIXA",
          "(SIOPI) Imovel Novo - fora do SFH - Nao empregado CAIXA",
          "(SIOPI) Imovel Novo - fora do SFH - Empregado CAIXA",
          "(SIPAH) Imovel novo",
          "(SIOPI) PORTABILIDADE - CCSBPE TX POS SFH - Aquisicao de Imovel Residencial Novo - N Empregado",
          "(SIOPI) PORTABILIDADE - CCSBPE TX POS SFH - Aquisicao de Imovel Residencial Novo - Empregado CAIXA",
          "(SIOPI) PORTABILIDADE - CCSBPE TX PSS FORA SFH - Aquisicao de Imsvel Residencial Novo - N Empregado",
          "(SIOPI) PORTABILIDADE - CCSBPE TX POS FORA SFH - Aquis de Imovel Resid Novo - Empregado CAIXA",
          "CCSBPE TX POS SFI - Aquisicao de Imovel Residencial Novo"
        ]
      },
      "Aquisição de imóvel residencial usado - Individual": {
        produtos: [
          "(SIOPI) Imovel Usado - SFH - Nao empregado CAIXA",
          "(SIOPI) Imovel Usado - SFH - Empregado CAIXA",
          "(SIOPI) Imovel Usado - fora do SFH - Nao empregado CAIXA",
          "(SIOPI) Imovel Usado - fora do SFH - Empregado CAIXA",
          "(SIPAH) Imovel usado",
          "(SIOPI) PORTABILIDADE - CCSBPE TX POS SFH - Aquisicao de Imovel Residencial Usado - N Empregado",
          "(SIOPI) PORTABILIDADE - CCSBPE TX POS SFH - Aquisicao de Imovel Residencial Usado - Empregado CAIXA",
          "(SIOPI) PORTABILIDADE - CCSBPE TX PSS FORA SFH - Aquisicao de Imsvel Residencial Usado - N Empregado",
          "(SIOPI) PORTABILIDADE - CCSBPE TX POS FORA SFH - Aquis de Imovel Resid Usado - Empregado CAIXA",
          "CCSBPE TX POS SFI - Aquisicao de Imovel Residencial Usado"
        ]
      },
      "Unidade Residencial de empreendimento": { produtos: ["(SIPAH) Imovel na Planta"] },
      "Aquisição de lote urbanizado - Individual": {
        produtos: [
          "Aquisicao de lote urbanizado individual",
          "(SIOPI) PORTABILIDADE - CCSBPE TX Pos FORA SFH - Aquisicao de Lote Urbanizado - Nao Empregado",
          "(SIOPI) PORTABILIDADE - CCSBPE TX Pos FORA SFH - Aquisicao de Lote Urbanizado - Empregado CAIXA",
          "(SIOPI) CCSBPE TX Pos Fora SFH  Aquisicao de Lote Urbanizado  Nao Empregado",
          "(SIOPI) CCSBPE TX POS FORA SFH  Aquisicao de Lote Urbanizado¿¿ Empregado CAIXA"
        ]
      },
      "Constr/aquisição de terreno e construção - Indiv": {
        produtos: [
          "Construcao em terreno proprio - Fora SFH Nao Empregado",
          "Construcao em terreno proprio - Fora SFH Empregado",
          "Construcao em terreno proprio - SFH/Pos Nao Empregado",
          "Construcao em terreno proprio - SFH/Pos Empregado",
          "Construcao/aq. terreno e const. - individual",
          "(SIOPI) CCSBPE TX POS SFH - Aquisicao de Terreno e Construcao - N Empregado",
          "(SIOPI) CCSBPE TX POS SFH - Aquisicao de Terreno e Construcao - Empregado CAIXA",
          "(SIOPI) CCSBPE TX POS FORA SFH - Aquisicao de Terreno e Construcao - N Empregado",
          "(SIOPI) CCSBPE TX POS FORA SFH - Aquisicao de Terreno e Construcao - Empregado CAIXA"
        ]
      },
      "Reforma e/ou ampliação de imóvel Resid - Indiv": {
        produtos: [
          "Reforma e/ou ampliacao",
          "(SIOPI) CCSBPE - Reforma e/ou Ampliacao - Imovel Residencia",
          "(SIOPI) CCSBPE - Reforma e/ou Ampliacao - Imovel Residencial - Empregado CAIXA"
        ]
      },
      "Transferência de dívida": { produtos: ["Transferência de divida"] },
      "Aquisição de imóvel de propriedade da CAIXA": { produtos: ["Imóvel de propriedade da CAIXA"] },
      "Aquisição de imóvel Comercial novo - Individual/Alocação de Recursos": {
        produtos: ["Aquisição de imóvel novo - Comercial"]
      },
      "Aquisição de imóvel Comercial usado - Individual": {
        produtos: [
          "Aquisicao de imovel usado - Comercial",
          "(SIOPI) CCSBPE TX POS FORA SFH - Aquisicao de Imovel Comercial Usado"
        ]
      },
      "Constr/aquisição de terreno e constr - Com - Indiv": {
        produtos: [
          "Construcao/aquis. terreno e construcao - Comercial",
          "(SIOPI) CCSBPE - Construcao em Terreno Proprio Comercial",
          "(SIOPI) CCSBPE - Aquisicao de Terreno e Construcao Comercial"
        ]
      },
      "Reforma e/ou ampliação imóvel Comerc - Individual": {
        produtos: ["Reforma e/ou ampliacao - Comercial", "(SIOPI) CCSBPE - Reforma e/ou Ampliacao - Imovel Comercial"]
      },
      "Aquisição de lote urbanizado Comerc - Individual": {
        produtos: [
          "Aquisicao de lote urbanizado individual - Comercial",
          "(SIOPI) CCSBPE TX POS SFI ¿¿¿ Aquisicao de Lote Urbanizado Comercial"
        ]
      },
      "Aquisição de Imóvel Comerc Reabilitado - Indiv": { produtos: null },
      "Unidade Comercial de empreendimento": { produtos: null }
    }
  },
  "FGTS - Faixa Estendida - Operações Especiais": {
    modalidades: {
      "Aquisição de imóvel novo": {
        produtos: [
          "CCFGTS - ALOCA¿¿O DE RECURSOS - PF - FAIXA EST - EMPREGADO CAIXA",
          "CCFGTS - ALOCA¿¿O DE RECURSOS - PF - FAIXA EST - NAO EMPREGADO",
          "CCFGTS - Aquisi¿¿o de Im¿vel Residencial Novo - Empregado CAIXA - Faixa Estendida",
          "CCFGTS - Aquisi¿¿o de Im¿vel Residencial Novo - N¿o Empregado - Faixa Estendida"
        ]
      },
      "Aquisição de imóvel na planta": {
        produtos: [
          "** CCFGTS - Unidade Complementar Apoio a Produ¿¿o Sem PJ - FAIXA EST (outras fontes)",
          "** CCFGTS - Unidade Complementar Apoio a Produ¿¿o Com PJ - FAIXA EST (outras fontes)"
        ]
      },
      "Aquisição de terreno e construção": {
        produtos: [
          "CCFGTS - Constru¿¿o em Terreno Pr¿prio - Empregado CAIXA - Faixa Estendida",
          "CCFGTS - Constru¿¿o em Terreno Pr¿prio - N¿o Empregado - Faixa Estendida",
          "CCFGTS - Aquisi¿¿o de Terreno e Constru¿¿o - Empregado CAIXA - Faixa Estendida",
          "CCFGTS - Aquisi¿¿o de Terreno e Constru¿¿o - N¿o Empregado - Faixa Estendida"
        ]
      }
    }
  }
};

const origensRecursos = _.keys(origensRecursosMap);

const modalidadePorOrigRecurso = origemRecurso => {
  return _.keys(_.get(origensRecursosMap, `${origemRecurso}.modalidades`));
};

const produtoPorOrigRecursoAndModalidade = (origemRecurso, modalidade) => {
  return _.get(origensRecursosMap, `${origemRecurso}.modalidades.${modalidade}.produtos`);
};

export default {
  origensRecursos,
  modalidadePorOrigRecurso,
  produtoPorOrigRecursoAndModalidade
};
