const tiposLogradouros = [
  "",
  "ACAMPAMENTO",
  "ACESSO",
  "ACESSO LOCAL",
  "ADRO",
  "AEROPORTO",
  "ALAMEDA",
  "ALTO",
  "ANTIGA ESTRADA",
  "ÁREA",
  "ÁREA ESPECIAL",
  "ARTERIA",
  "ATALHO",
  "AVENIDA",
  "AVENIDA CONTORN",
  "AVENIDA MARGDIR",
  "AVENIDA MARGESQ",
  "AVENIDA MARGINA",
  "AVENIDA VELHA",
  "BAIXA",
  "BALÃO",
  "BALNEÁRIO",
  "BECO",
  "BELVEDERE",
  "BLOCO",
  "BLOCOS",
  "BOSQUE",
  "BOULEVARD",
  "BULEVAR",
  "BURACO",
  "CAIS",
  "CALCADA",
  "CAMINHO",
  "CAMPO",
  "CANAL",
  "CHACARA",
  "CHAPADAO",
  "CICLOVIA",
  "CIRCULAR",
  "COLONIA",
  "COMPLEXO VIARIO",
  "CONDOMINIO",
  "CONJUNTO",
  "CONJUNTO MUTIRA",
  "CONTORNO",
  "CORREDOR",
  "CORREGO",
  "DESCIDA",
  "DESVIO",
  "DISTRITO",
  "ELEVADA",
  "ENTRADA PARTICU",
  "ENTRE QUADRA",
  "ESCADA",
  "ESCADARIA",
  "ESPLANADA",
  "ESTACAO",
  "ESTACIONAMENTO",
  "ESTADIO",
  "ESTANCIA",
  "ESTRADA",
  "ESTRADA ANTIGA",
  "ESTRADA DE LIGA",
  "ESTRADA ESTADUA",
  "ESTRADA MUNICIP",
  "ESTRADA PARTICU",
  "ESTRADA VELHA",
  "FAVELA",
  "FAZENDA",
  "FEIRA",
  "FERROVIA",
  "FONTE",
  "FORTE",
  "GALERIA",
  "GRANJA",
  "HABITACIONAL",
  "ILHA",
  "JARDIM",
  "JARDINETE",
  "LADEIRA",
  "LAGO",
  "LAGOA",
  "LARGO",
  "LOTEAMENTO",
  "MARINA",
  "MÓDULO",
  "MONTE",
  "MORRO",
  "NÚCLEO",
  "PARADA",
  "PARADOURO",
  "PARALELA",
  "PARQUE",
  "PARQUE MUNICIPA",
  "PARQUE RESIDÊNC",
  "PASSAGEM",
  "PASSAGEM DE PED",
  "PASSAGEM SUBTER",
  "PASSARELA",
  "PASSEIO",
  "PATIO",
  "PONTA",
  "PONTE",
  "PORTO",
  "PRACA",
  "PRAÇA DE ESPORT",
  "PRAIA",
  "PRIMEIRA LADEIR",
  "PROLONGAMENTO",
  "QUADRA",
  "QUARTA LADEIRA",
  "QUINTA",
  "QUINTA LADEIRA",
  "QUINTAS",
  "RAMAL",
  "RAMPA",
  "RECANTO",
  "RECREIO",
  "RESIDENCIAL",
  "RETA",
  "RETIRO",
  "RETORNO",
  "RODO ANEL",
  "RODOVIA",
  "ROTATORIA",
  "RÓTULA",
  "RUA",
  "RUA DE LIGAÇÃO",
  "RUA DE PEDESTRE",
  "RUA INTEGRAÇÃO",
  "RUA PARTICULAR",
  "RUA VELHA",
  "SEGUNDA LADEIRA",
  "SERVIDÃO",
  "SETOR",
  "SÍTIO",
  "SUBIDA",
  "TERCEIRA LADEIR",
  "TERMINAL",
  "TRAVESSA",
  "TRAVESSA PARTIC",
  "TRAVESSA VELHA",
  "TRECHO",
  "TREVO",
  "TRINCHEIRA",
  "TUNEL",
  "UNIDADE",
  "VALA",
  "VALE",
  "VARIANTE",
  "VEREDA",
  "VIA",
  "VIA COLETORA",
  "VIA DE ACESSO",
  "VIA DE PEDESTRE",
  "VIA ELEVADO",
  "VIA LITORANEA",
  "VIA LOCAL",
  "VIADUTO",
  "VIELA",
  "VILA",
  "ZIGUE-ZAGUE",
  "1A AVENIDA",
  "1A PARALELA",
  "1A RUA",
  "1A SUBIDA",
  "1A TRAVESSA",
  "1A VILA",
  "1O ALTO",
  "1O BECO",
  "1O PARQUE",
  "10A RUA",
  "10A TRAVESSA",
  "11A RUA",
  "11A TRAVESSA",
  "12A RUA",
  "12A TRAVESSA",
  "13A TRAVESSA",
  "14A TRAVESSA",
  "15A TRAVESSA",
  "16A TRAVESSA",
  "18A TRAVESSA",
  "19A TRAVESSA",
  "2A AVENIDA",
  "2A PARALELA",
  "2A RUA",
  "2A SUBIDA",
  "2A TRAVESSA",
  "2A VILA",
  "2O ALTO",
  "2O BECO",
  "2O PARQUE",
  "3A AVENIDA",
  "3A PARALELA",
  "3A RUA",
  "3A SUBIDA",
  "3A TRAVESSA",
  "3A VILA",
  "3O ALTO",
  "3O BECO",
  "3O PARQUE",
  "4A AVENIDA",
  "4A PARALELA",
  "4A RUA",
  "4A SUBIDA",
  "4A TRAVESSA",
  "4A VILA",
  "4O ALTO",
  "4O BECO",
  "5A AVENIDA",
  "5A PARALELA",
  "5A RUA",
  "5A SUBIDA",
  "5A TRAVESSA",
  "5A VILA",
  "5O ALTO",
  "5O BECO",
  "6A AVENIDA",
  "6A RUA",
  "6A SUBIDA",
  "6A TRAVESSA",
  "7A RUA",
  "7A TRAVESSA",
  "8A RUA",
  "8A TRAVESSA",
  "9A RUA",
  "9A TRAVESSA"
];

export default tiposLogradouros;
