<template>
  <v-app id="inspire">
    <SideMenu
      :clipped="$vuetify.breakpoint.lgAndUp"
      :opened="sideMenuOpened"
      class="d-print-none"
      v-on:side-menu-click="sideMenuClicked"
    />

    <AppBar
      :color="color"
      :opened="sideMenuOpened"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      :title="title"
      v-on:side-menu-click="sideMenuClicked"
      class="d-print-none"
    />

    <v-main>
      <error />
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer :color="color" app class="d-print-none">
      <v-spacer></v-spacer>
      <span class="white--text mr-3">{{ version }} - &copy; {{ new Date().getFullYear() }}</span>
    </v-footer>

    <!--<Snackbar />-->
  </v-app>
</template>

<script>
import Error from "./components/Error";
import SideMenu from "./components/main/SideMenu";
import AppBar from "./components/main/AppBar";
import { CONFIG } from "./config";
import auth0 from "./api/auth0";

export default {
  name: "App",
  components: {
    SideMenu,
    AppBar,
    Error
  },
  data: () => ({
    sideMenuOpened: false,
    version: CONFIG.version,
    title: CONFIG.title,
    color: "#204796"
  }),
  async created() {
    try {
      await auth0.renewTokens();
    } catch {
      // Supress the 'not logged in' error as we can illegitimately get that
      // when processing the callback url
    }
  },
  methods: {
    sideMenuClicked(value) {
      this.sideMenuOpened = value;
    },
    handleLoginEvent(data) {
      this.$store.dispatch("auth/handleLogin", data);
    }
  }
};
</script>
