import ROUTERS from "../../routes/router-constants";
import authConfig from "../../../auth_config.json";

const state = {
  menuItems: [
    {
      title: "Home",
      icon: "fa-home",
      to: ROUTERS.home.path,
      visible: false,
      roles: authConfig.userRoles
    },
    {
      title: "Perfil",
      icon: "fa-user",
      to: { name: ROUTERS.perfil.name },
      visible: true,
      roles: authConfig.userRoles
    },
    {
      title: "Corrigir",
      icon: "fa-edit",
      to: { name: ROUTERS.correcao.name },
      visible: true,
      roles: authConfig.professorRoles
    },
    {
      title: "Dossiês",
      icon: "fa-puzzle-piece",
      to: { name: ROUTERS.dossies.name },
      visible: true,
      roles: authConfig.adminRoles
    },
    {
      title: "Relatório",
      icon: "fa-chart-line",
      to: { name: ROUTERS.relatorio.name },
      visible: true,
      roles: authConfig.professorRoles
    }
  ]
};

// getters
const getters = {
  menuItemsByRoles: state => roles => {
    return state.menuItems
      .filter(value => value.visible)
      .filter(value => value.roles.some(role => roles.includes(role)));
  }
};

// mutations
const mutations = {};

// actions
const actions = {
  menuItemsByRoles({ getters }, roles) {
    return getters.menuItemsByRoles(roles);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
