/* eslint-disable max-len */
import ROUTERS from "./router-constants";


const assinoRouter = [
  {
    // CAIXA AQUI ROOT
    path: ROUTERS.assino.path,
    name: ROUTERS.assino.name,
    component: () => import(/* webpackChunkName: "assino.resposta-assino.main" */ "../views/assino/resposta-assino/RespostaAssino"),
  }
];

export default assinoRouter;
