import _ from "lodash";
import { createHelpers, getField } from "vuex-map-fields";
import DadosSituacao from "../../api/model/dados-situacao";
import DadosAmortizacao from "../../api/model/dados-amortizacao";
import DadosEnquadramento from "../../api/model/dados-enquadramento";
import DadosFgts from "../../api/model/dados-fgts";
import DadosIndexador from "../../api/model/dados-indexador";
import DadosRedutorTaxa from "../../api/model/dados-redutor-taxa";


const state = {
  dadosSituacao: DadosSituacao,
  dadosFgts: DadosFgts,
  dadosRedutorTaxa: DadosRedutorTaxa,
  dadosEnquadramento: DadosEnquadramento,
  dadosAmortizacao: DadosAmortizacao,
  dadosIndexador: DadosIndexador
};

// getters
const getters = {
  getField
};

// mutations
const mutations = {};

// actions
const actions = {};

// EXPORTS
export const { mapFields: mapDadosRepostaAssinoFields } = createHelpers({
  getterType: "dadosRepostaAssino/getField"
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
