/* eslint-disable max-len */
import ROUTERS from "./router-constants";

const caixaAquiCadastroClientesRouter = [
  {
    // CADASTRO CLIENTES
    path: ROUTERS.caixaqui.cadastroClientes.path,
    name: ROUTERS.caixaqui.cadastroClientes.name,
    component: () =>
      import(/* webpackChunkName: "caixaqui.cadastro-cliente.main" */ "../views/caixaqui/CaixaquiFormMain"),
    children: [
      {
        // CPF CLIENTE
        path: ROUTERS.caixaqui.cadastroClientes.cpfCliente.path,
        name: ROUTERS.caixaqui.cadastroClientes.cpfCliente.name,
        meta: {
          title: "Cadastro de Clientes",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          proximoLink: null // don't need next
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.cpf" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClientesCpfCliente"
          )
      },
      {
        // DADOS CADASTRAIS
        path: ROUTERS.caixaqui.cadastroClientes.dadosCadastrais.path,
        name: ROUTERS.caixaqui.cadastroClientes.dadosCadastrais.name,
        meta: {
          title: "Cadastro de Clientes",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.cadastroClientes.cpfCliente.name },
          proximoLink: { name: ROUTERS.caixaqui.cadastroClientes.endereco.name }
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.dados-cadastrais" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClientesDadosCadastrais"
          )
      },
      {
        // ENDERECO, TELEFONES, EMAIL
        path: ROUTERS.caixaqui.cadastroClientes.endereco.path,
        name: ROUTERS.caixaqui.cadastroClientes.endereco.name,
        meta: {
          title: "Cadastro de Clientes",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.cadastroClientes.dadosCadastrais.name },
          proximoLink: { name: ROUTERS.caixaqui.cadastroClientes.rendaFormal.name }
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.endereco" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClientesEndereco"
          )
      },
      {
        // RENDA FORMAL
        path: ROUTERS.caixaqui.cadastroClientes.rendaFormal.path,
        name: ROUTERS.caixaqui.cadastroClientes.rendaFormal.name,
        meta: {
          title: "Cadastro de Clientes",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.cadastroClientes.endereco.name },
          proximoLink: { name: ROUTERS.caixaqui.cadastroClientes.rendaInformal.name }
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.renda-formal" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClientesRendaFormal"
          )
      },
      {
        // RENDA NAO CADASTRADA / INFORMAL
        path: ROUTERS.caixaqui.cadastroClientes.rendaInformal.path,
        name: ROUTERS.caixaqui.cadastroClientes.rendaInformal.name,
        meta: {
          title: "Cadastro de Clientes",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.cadastroClientes.rendaFormal.name },
          proximoLink: { name: ROUTERS.caixaqui.cadastroClientes.patrimonioConsolidado.name }
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.renda-informal" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClientesRendaInformal"
          )
      },
      {
        // PATRIMONIO CONSOLIDADO
        path: ROUTERS.caixaqui.cadastroClientes.patrimonioConsolidado.path,
        name: ROUTERS.caixaqui.cadastroClientes.patrimonioConsolidado.name,
        meta: {
          title: "Cadastro de Clientes",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.cadastroClientes.rendaInformal.name },
          proximoLink: { name: ROUTERS.caixaqui.cadastroClientes.agenciaRelacionamentoDefinidaCliente.name }
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.informacoes-patrimoniais-imoveis" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClientesPatrimonioConsolidado"
          )
      },
      {
        // AGÊNCIA DE RELACIONAMENTO (DEFINIDA PELO CLIENTE)
        path: ROUTERS.caixaqui.cadastroClientes.agenciaRelacionamentoDefinidaCliente.path,
        name: ROUTERS.caixaqui.cadastroClientes.agenciaRelacionamentoDefinidaCliente.name,
        meta: {
          title: "Cadastro de Clientes",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.cadastroClientes.patrimonioConsolidado.name },
          proximoLink: null
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.agencia-relacionamento-definida-cliente" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClientesAgenciaRelacionamentoDefinidaCliente"
          )
      },
      {
        // RESUMO
        path: ROUTERS.caixaqui.cadastroClientes.resumo.path,
        name: ROUTERS.caixaqui.cadastroClientes.resumo.name,
        meta: {
          title: "Cadastro de Clientes",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.cadastroClientes.agenciaRelacionamentoDefinidaCliente.name },
          proximoLink: null
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.resumo" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClientesResumo"
          )
      },
      {
        // FORMULARIOS ENVIADOS
        path: ROUTERS.caixaqui.formulariosEnviados.path,
        name: ROUTERS.caixaqui.formulariosEnviados.name,
        meta: {
          title: "Consulta de Formulários Enviados",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          proximoLink: null
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.main" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClienteFormulariosEnviados"
          )
      },
      {
        // FORMULARIOS ENVIADOS APROVADOS
        path: ROUTERS.caixaqui.formulariosEnviadosAprovados.path,
        name: ROUTERS.caixaqui.formulariosEnviadosAprovados.name,
        meta: {
          title: " Consulta de Formulários Enviados",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.formulariosEnviados.name },
          proximoLink: null
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.main" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClienteFormulariosEnviadosAprovados"
          )
      },
      {
        // FORMULARIOS ENVIADOS REPROVADOS
        path: ROUTERS.caixaqui.formulariosEnviadosReprovados.path,
        name: ROUTERS.caixaqui.formulariosEnviadosReprovados.name,
        meta: {
          title: "Consulta de Formulários Enviados",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.formulariosEnviados.name },
          proximoLink: null
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.main" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClienteFormulariosEnviadosReprovados"
          )
      },
      {
        // FORMULARIOS ENVIADOS CONDIONADAS
        path: ROUTERS.caixaqui.formulariosEnviadosCondicionados.path,
        name: ROUTERS.caixaqui.formulariosEnviadosCondicionados.name,
        meta: {
          title: "Consulta de Formulários Enviados",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          sairLink: ROUTERS.caixaqui.cadastroClientes.sairLink,
          voltarLink: { name: ROUTERS.caixaqui.formulariosEnviados.name },
          proximoLink: null
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.cadastro-cliente.main" */ "../views/caixaqui/cadastro-clientes/CaixaquiCadastroClienteFormulariosEnviadosCondicionados"
          )
      }
    ]
  }
];

export default caixaAquiCadastroClientesRouter;
