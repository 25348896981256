const grauInstrucoes = [
  "",
  "ATÉ 4A SÉRIE INCOMPLETA DO ENSINO FUNDAMENTAL",
  "COM 4A SÉRIE COMPLETA DO ENSINO FUNDAMENTAL",
  "DA 5A A 8A SÉRIE INCOMPLETA DO ENSINO FUNDAMENTAL",
  "ENSINO FUNDAMENTAL COMPLETO",
  "ENSINO MÉDIO INCOMPLETO",
  "ENSINO MÉDIO COMPLETO",
  "SUPERIOR INCOMPLETO",
  "SUPERIOR COMPLETO",
  "ESPECIALIZAÇÃO/PÓS GRADUAÇÃO",
  "MESTRADO",
  "DOUTORADO"
];

export default grauInstrucoes;
