var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "fill-height", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto d-inline-flex pa-2 justify-center align-center",
          attrs: { height: "150", outlined: "", tile: "" }
        },
        [
          _c("span", { staticClass: "display-3 font-weight-black" }, [
            _vm._v("403")
          ]),
          _c("span", { staticClass: "mx-5 display-1 font-weight-medium" }, [
            _vm._v("-")
          ]),
          _c("span", { staticClass: "display-1 font-weight-medium" }, [
            _vm._v("Sem permissão para acessar este recurso")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }