const ocupacoesPorTipo = {
  FORMAL: [
    "ADMINISTRADOR",
    "ADVOGADO",
    "AGENCIADOR DE PROPAGANDA",
    "AGENTE ADMINISTRATIVO",
    "AGENTE DE SERVICOS FUNERARIOS E EMBALSAMADOR",
    "AGENTE DE VIAGEM E GUIA DE TURISMO",
    "AGRONOMO",
    "ALFAIATE",
    "ANALISTA DE SISTEMAS",
    "APOSENTADO EXCETO FUNCIONARIO PUBLICO",
    "ARQUITETO",
    "ASSISTENTE SOCIAL",
    "ASTRONOMO E METEOROLOGISTA",
    "ATLETA PROFISSIONAL E TECNICO EM DESPORTOS",
    "ATOR E DIRETOR DE ESPETACULOS PUBLICOS",
    "ATUARIO E MATEMATICO",
    "AUXILIAR DE ESCRITORIO E ASSEMELHADOS",
    "AUXILIAR DE LABORATORIO",
    "BANCARIO E ECONOMIARIO",
    "BIBLIOTECARIO ARQUIVISTA MUSEOLOGO E ARQUEOLOGO",
    "BIOLOGO E BIOMEDICO",
    "BOLSISTA ESTAGIARIO E ASSEMELHADOS",
    "BOMBEIRO E INSTALADOR DE GAS AGUA ESGOTO E ASSEMELHADOS",
    "CABELEIREIRO BARBEIRO MANICURE PEDICURE MAQUILADOR ESTETICISTA E MASSAGISTA",
    "CANTOR E COMPOSITOR",
    "CAPITALISTA RECEBENDO RENDIMENTO DE APLICACAO DE CAPITAL EM ATIVOS FINANCEIROS",
    "CHEFE INTERMEDIARIO",
    "COMANDANTE DE EMBARCACOES",
    "COMISSARIO DE BORDO",
    "COMUNICOLOGO",
    "CONTADOR",
    "CONTRAMESTRE DE EMBARCACOES",
    "COREOGRAFO E BAILARINO",
    "CORRETOR DE IMOVEIS, SEGUROS, TITULOS E VALORES",
    "DECORADOR",
    "DELEGADO DE POLICIA",
    "DEMONSTRADOR",
    "DESENHISTA COMERCIAL",
    "DESENHISTA INDUSTRIAL",
    "DESENHISTA TECNICO",
    "DESPACHANTE INCLUSIVE O ADUANEIRO",
    "DIPLOMATA",
    "DIRETOR DE EMPRESAS",
    "DIRETOR DE ESTABELECIMENTO DE ENSINO",
    "ECONOMISTA",
    "ELETRICISTA E ASSEMELHADOS",
    "ELETRICISTA MANUTENCAO VEICULO AUTO MAQ APARELHOS ELETRICO ELETRONICO E TELECOM",
    "EMPREGADO DOMESTICO",
    "EMPRESARIO E PRODUTOR DE ESPETACULOS PUBLICOS",
    "ENFERMEIRO E NUTRICIONISTA",
    "ENGENHEIRO",
    "ESCULTOR, PINTOR E ASSEMELHADOS",
    "ESPOLIO",
    "ESTATISTICO",
    "ESTIVADOR CARREGADOR EMBALADOR E ASSEMELHADOS",
    "FARMACEUTICO",
    "FEIRANTE",
    "FISCAL",
    "FISICO",
    "FISIOTERAPEUTA E TERAPEUTA OCUPACIONAL",
    "FONOAUDIOLOGO",
    "FUNCIONARIO PUBLICO CIVIL APOSENTADO",
    "GARIMPEIRO",
    "GEOGRAFO",
    "GEOLOGO",
    "GERENTE",
    "GOVERNANTA DE HOTEL CAMAREIRO PORTEIRO COZINHEIRO E GARCOM",
    "JOALHEIROS E OURIVES",
    "JORNALEIRO",
    "JORNALISTA",
    "LANTERNEIRO E PINTOR DE VEICULOS METALICOS",
    "LEILOEIRO, AVALIADOR E ASSEMELHADOS",
    "LOCUTOR E COMENTARISTA DE RADIO E TELEVISAO E RADIALISTA",
    "MAQUINISTA E FOGUISTA DE EMBARCACOES, LOCOMOTIVAS E ASSEMELH",
    "MARINHEIRO E ASSEMELHADOS",
    "MECANICO DE MANUTENCAO DE VEICULOS AUTOMOTORES E MAQUINAS",
    "MECANICO MANUTENCAO MONTADOR PREPARADOR OPERADOR DE MAQ E APARELHOS PROD INDUST",
    "MEDICO",
    "MESTRE E CONTRAMESTRE",
    "MILITAR EM GERAL",
    "MILITAR REFORMADO",
    "MODELO DE MODAS",
    "MOTORISTA DE VEICULOS DE TRANSPORTE DE CARGA",
    "MOTORISTA DE VEICULOS DE TRANSPORTE DE PASSAGEIROS",
    "MUSICO",
    "OCUPANTE DE CARGO DE DIRECAO E ASSESSORAMENTO INTERMEDIARIO",
    "OCUPANTE DE CARGO DE DIRECAO E ASSESSORAMENTO SUPERIOR",
    "ODONTOLOGO",
    "OFICIAIS DAS FORCAS ARMADAS E FORCAS AUXILIARES",
    "OPERADOR DE CAMERAS DE CINEMA E TELEVISAO",
    "OUTROS TRABALHADORES DE NIVEL SUPERIOR LIGADOS AO ENSINO",
    "PENSIONISTA",
    "PILOTO DE AERONAVES",
    "PODER EXECUTIVO PRESIDENTE MINISTRO GOVERNADOR SECRETARIO E MEMBROS DO MP",
    "PODER JUDICIARIO MINISTRO DE TRIBUNAL SUPERIOR DESEMBARGADOR E JUIZ",
    "PODER LEGISLATIVO SENADOR DEPUTADO FEDERAL DEPUTADO ESTADUAL E VEREADOR",
    "PORTEIRO DE EDIFICIO ASCENSORISTA GARAGISTA E FAXINEIRO",
    "PROCURADOR E ASSEMELHADOS",
    "PROFESSORES DE ENSINO DE PRIMEIRO E SEGUNDO GRAUS",
    "PROFESSORES DE ENSINO SUPERIOR",
    "PROFISSAO CRIADA PARA TESTE",
    "PROFISSIONAIS DE LETRAS E DE ARTES",
    "PROPRIETARIO DE ESTABELECIMENTO AGRICOLA DA PECUARIA FLORESTAL",
    "PROPRIETARIO DE ESTABELECIMENTO COMERCIAL",
    "PROPRIETARIO DE ESTABELECIMENTO DE PRESTACAO DE SERVICOS",
    "PROPRIETARIO DE ESTABELECIMENTO INDUSTRIAL",
    "PROPRIETARIO DE IMOVEL, RECEBENDO RENDIMENTO DE ALUGUEL",
    "PROPRIETARIO DE MICROEMPRESA",
    "PROTETICO",
    "PSICOLOGO",
    "PUBLICITARIO",
    "QUIMICO",
    "RELACOES PUBLICAS",
    "SACERDOTES OU MEMBROS DE ORDENS OU SEITAS RELIGIOSAS",
    "SECRETARIO ESTENOGRAFO DATILOGRAFO RECEPCIONISTA TELEFONISTA E ASSEMELHADOS",
    "SECURITARIO",
    "SERVENTUARIO DE JUSTICA",
    "SERVIDOR PUBLICO ESTADUAL",
    "SERVIDOR PUBLICO FEDERAL",
    "SERVIDOR PUBLICO MUNICIPAL",
    "SOCIOLOGO",
    "SUPERVISOR, INSPETOR E AGENTE DE COMPRAS E VENDAS",
    "TABELIAO",
    "TECNICO DE BIOLOGIA",
    "TECNICO DE CONTABILIDADE E DE ESTATISTICA",
    "TECNICO DE ELETRICIDADE, ELETRONICA E TELECOMUNICACOES",
    "TECNICO DE LABORATORIO E RAIO X",
    "TECNICO DE MECANICA",
    "TECNICO DE QUIMICA",
    "TECNICO EM AGRONOMIA E AGRIMENSURA",
    "TECNOLOGO",
    "TRABALHADOR AGRICOLA",
    "TRABALHADOR DA PECUARIA",
    "TRABALHADOR DA PESCA",
    "TRABALHADOR DE ARTES GRAFICAS",
    "TRABALHADOR DE CONSTRUCAO CIVIL",
    "TRABALHADOR DE FABRICACAO DE ARTEFATOS DE MADEIRA",
    "TRABALHADOR DE FABRICACAO DE CALCADOS E ARTEFATOS DE COURO",
    "TRABALHADOR DE FABRICACAO DE PAPEL E PAPELAO",
    "TRABALHADOR DE FABRICACAO DE PRODUTOS DE BORRACHA E PLASTICO",
    "TRABALHADOR DE FABRICACAO DE PRODUTOS TEXTEIS EXCETO ROUPAS",
    "TRABALHADOR DE FABRICACAO DE ROUPAS",
    "TRABALHADOR DE FABRICACAO E PREPARACAO DE ALIMENTOS E BEBIDA",
    "TRABALHADOR DE INSTALACOES DE PROCESSAMENTO QUIMICO",
    "TRABALHADOR DE TRATAMENTO DE FUMO E DE FABRICACAO DE CIGARROS E CHARUTOS",
    "TRABALHADOR DE USINAGEM DE METAIS",
    "TRABALHADOR DOS SERVICOS DE CONTABILIDADE DE CAIXA E TRABALHADORES ASSEMELHADOS",
    "TRABALHADOR FLORESTAL",
    "TRABALHADOR METALURGICO E SIDERURGICO",
    "TRABALHADORES DOS SERVICOS DE PROTECAO E SEGURANCA EXCETO MILITAR",
    "VENDEDOR DE COMERCIO VAREJISTA E ATACADISTA",
    "VENDEDOR PRACISTA REPRESENTANTE COMERCIAL CAIXEIRO VIAJANTE E ASSEMELHADOS",
    "VETERINARIO E ZOOTECNISTA"
  ],
  INFORMAL: [
    "ACOUGUEIRO",
    "ACUPUNTURISTA",
    "ADESTRADOR DE ANIMAIS",
    "ADMINISTRADOR",
    "ADVOGADO",
    "AFIADOR",
    "AGRICULTOR",
    "AGRONOMO",
    "ALAMBIQUEIRO",
    "ALFAIATE",
    "ANALISTA DE SISTEMA",
    "ARQUITETO",
    "ARTISTA DE CIRCO",
    "ARTISTA DE DANÇA",
    "ARTISTA VISUAL",
    "ASTROLOGO E NUMEROLOGO",
    "ATIVIDADES RELIGIOSAS",
    "ATLETA",
    "ATOR",
    "AUXILIAR GERAL",
    "BABA",
    "BARBEIRO",
    "BIOLOGO",
    "BIOMEDICO",
    "BORDADOR",
    "BORRACHEIRO",
    "CABELEIREIRO(A)",
    "CAMAREIRO(A)",
    "CAMINHONEIRO AUTONOMO",
    "CANTOR(A)",
    "CARPINTEIRO",
    "CARREGADOR DE MERCADORIAS",
    "CARROCEIRO",
    "CARTOGRAFO",
    "CATADORES DE MATERIAL RECICLAVEL",
    "CERAMISTA",
    "CHAVEIRO",
    "COBRADOR",
    "COMENTARISTA DE RADIO E TELEVISAO",
    "COMPOSITOR",
    "CONFEITEIRO(A)",
    "CONSERTADOR DE APARELHO ELETRONICO ELETRODOMESTICO",
    "CONSULTOR",
    "CONTADOR",
    "CORRETOR",
    "COSTUREIRO(A)",
    "COZINHEIRO(A)",
    "CROCHETEIRO A MAO",
    "CUIDADOR DE IDOSOS",
    "DECORADOR(A)",
    "DEDETIZADOR",
    "DEPOSITOS PCI PAI",
    "DESENHISTA",
    "DESPACHANTE",
    "DIGITADOR",
    "ECONOMISTA",
    "ELETRICISTA",
    "ENCANADOR",
    "ENFERMEIRO(A)",
    "ENGENHEIRO",
    "ENGRAXATE",
    "ESOTERICO E PARANORMAL",
    "ESTETICISTA",
    "ESTOFADOR",
    "EXTRATIVISTA",
    "FARMACEUTICO",
    "FAXINEIRO(A)",
    "FEIRANTE",
    "FISIOTERAPEUTA",
    "FONAUDIOLOGO",
    "FOTOGRAFO(A)",
    "FUNILEIRO",
    "GANDULA",
    "GARCOM GARCONETE",
    "GEOLOGO",
    "GESSEIRO",
    "GUARDADOR DE VEÍCULOS (FLANELINHA)",
    "GUIA DE TURISMO",
    "INTERPRETE",
    "JARDINEIRO",
    "JOALHEIRO E OURIVES",
    "JORNALEIRO",
    "JORNALISTA",
    "LADRILHEIRO",
    "LAVADOR DE VEÍCULOS",
    "LAVADOR E PASSADOR DE ROUPAS",
    "LIMPADOR DE FACHADAS",
    "MANICURE, PEDICURE",
    "MANOBRISTA",
    "MAQUIADOR",
    "MARCENEIRO",
    "MASSAGISTA",
    "MECÂNICO",
    "MINHOCULTOR",
    "MODELO.",
    "MONTADOR DE MÓVEIS",
    "MOTOBOY",
    "MOTORISTA",
    "MUSICO",
    "MÉDICO",
    "NUTRICIONISTA",
    "ODONTOLOGO",
    "OLEIRO (FAB TELHAS E TIJOLOS)",
    "OPERADOR DE CÂMERA",
    "PADEIRO",
    "PECUARISTA",
    "PEDREIRO",
    "PENSÃO INFORMAL",
    "PESCADOR",
    "PESQUISADOR",
    "PILOTO DE AERONAVE",
    "PINTOR",
    "PIPOQUEIRO",
    "PODÓLOGO",
    "POETA",
    "PORTEIRO, VIGIA",
    "PROFESSOR",
    "PROFISSIONAL DO SEXO",
    "PROGRAMADOR",
    "PROMOTOR DE EVENTOS",
    "PROTÉTICO",
    "PSICÓLOGO",
    "PUBLICITÁRIO",
    "QUIROPRAXISTA",
    "RAIZEIRO",
    "RELOJOEIRO",
    "REPRESETANTE COMERCIAL",
    "SACOLEIRA",
    "SALVA-VIDAS",
    "SAPATEIRO",
    "SERRALHEIRO",
    "SERVENTE DE OBRAS",
    "SERVIÇOS GERAIS",
    "SOCIO DE EMPRESA",
    "SOLDADOR",
    "TAXISTA",
    "TELHADOR",
    "TOPÓGRAFO",
    "TRABALHADOR DOMÉSTICO",
    "TRABALHADOR RURAL",
    "TRADUTOR",
    "TRATORISTA",
    "VASSOUREIRO",
    "VENDEDOR (DOMICÍLIO, AMBULANTES, BANCAS)",
    "VETERINÁRIO",
    "VIDRACEIRO",
    "VIGILANTE E GUARDA DE SEGURANÇA",
    "ZELADOR",
    "ZOOTECNISTA"
  ]
};

export default ocupacoesPorTipo;
