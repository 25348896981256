const agenciasCaixaEstadoCidade = {
  MG: {
    "BELO HORIZONTE": ["0081 TUPINAMBAS, MG",
    "0082 BARREIRO, MG",
    "0083 BARRO PRETO, MG",
    "0084 SECULO, MG",
    "0085 INCONFIDENCIA, MG",
    "0086 FLORESTA, MG",
    "0087 HORTO FLORESTAL, MG",
    "0089 PADRE EUSTAQUIO, MG",
    "0090 IMPERADOR, MG",
    "0091 SAVASSI, MG",
    "0092 RENASCENCA, MG",
    "0093 SANTA EFIGENIA, MG",
    "0094 GUAJAJARAS, MG",
    "0536 OLEGARIO MACIEL, MG",
    "0620 JUSTICA TRABALHO BELO HORIZONTE, MG",
    "0621 JUSTICA FEDERAL BELO HORIZONTE, MG",
    "0681 MANGABEIRAS, MG",
    "0814 CEFET, MG",
    "0815 MINAS SHOPPING, MG",
    "0818 SESC, MG",
    "0821 UFMG, MG",
    "0841 SENAC, MG",
    "0843 FACULDADE DE ENGENHARIA, MG",
    "0935 SANTO AGOSTINHO, MG",
    "1022 VENDA NOVA, MG",
    "1149 BELO HORIZONTE, MG",
    "1422 BETANIA, MG",
    "1530 LUXEMBURGO, MG",
    "1532 CARMO-SION, MG",
    "1533 MINAS TENIS, MG",
    "1640 CALAFATE, MG",
    "1667 PARC ROYAL, MG",
    "1746 PAMPULHA, MG",
    "2161 ALTEROSAS, MG",
    "2187 21 DE ABRIL, MG",
    "2255 ABC, MG",
    "2279 BAIRRO CEU AZUL, MG",
    "2323 DEPARTAMENTO DE POLICIA FEDERAL, MG",
    "2333 CMBH, MG",
    "2336 TRT 3A REGIAO BELO HORIZONTE, MG",
    "2381 SISTEMA FIEMG, MG",
    "2426 ITACOLOMI, MG",
    "2427 ALIPIO DE MELO, MG",
    "2432 VILA RICA, MG",
    "2763 ALEMG, MG",
    "2922 BELVEDERE, MG",
    "2923 BURITIS, MG",
    "2983 LAGOA DO NADO, MG",
    "2984 CELSO FURTADO, MG",
    "3155 SHOPPING ESTACAO BH, MG",
    "3311 SHOPPING DEL REY, MG",
    "3552 JOAO GUIMARAES ROSA, MG",
    "3553 SINFRONIO BROCHADO, MG",
    "3611 PRESIDENTE JOSE ALENCAR, MG",
    "3622 DIGITAL BELO HORIZONTE LESTE, MG",
    "3665 PRESIDENTE JK, MG",
    "3730 DIGITAL BELO HORIZONTE OESTE, MG",
    "3892 VAREJO DIGITAL BELO HORIZONTE",
    "3992 TCE, MG",
    "4157 SHOPPING CIDADE, MG",
    "4216 BAIRRO SANTA MONICA, MG",
    "4255 CORPORATIVO BELO HORIZONTE",
    "4257 EMPRESARIAL BELO HORIZONTE, MG",
    "4387 BAIRRO SANTA AMELIA, MG",
    "4534 SANTO ANTONIO, MG",
    "4785 RAJA GABAGLIA, MG",
    "4855 BAIRRO SALGADO FILHO, MG",
    "4861 LOURDES, MG",
    "4972 BAIRRO CORACAO EUCARISTICO, MG"
  ],
  BETIM: ["0892 BETIM, MG",
    "1068 SHOPPING MONTE CARMO, MG",
    "2464 CAPELA NOVA, MG",
    "2837 PORTAL DOS SERTOES, MG",
    "3814 SAO CAETANO, MG"],
  CONTAGEM: ["0893 CONTAGEM, MG",
    "1402 TRT CONTAGEM, MG",
    "1529 VALE VERDE, MG",
    "1639 JARDIM INDUSTRIAL, MG",
    "2940 JOAQUIM DE OLIVEIRA, MG",
    "3797 RESSACA, MG"]},
  SP: {
    AMERICANA: ["0278 AMERICANA, SP",
      "1814 AV CILLOS, SP",
      "2102 PRACA XV DE NOVEMBRO, SP",
      "2156 JUSTICA FEDERAL AMERICANA, SP",
      "3296 SAO VITO, SP",
      "4493 AV IACANGA, SP"
    ],
    HORTOLANDIA: ["4088 HORTOLANDIA, SP",
      "4226 JARDIM ROSOLEN, SP",
      "4227 JARDIM AMANDA, SP"
    ],
    CAMPINAS: [
      "0296 CAMPINAS, SP",
      "0676 CONCEICAO, SP",
      "0999 JD. NOVA EUROPA, SP",
      "1160 PARQUE PRADO, SP",
      "1211 MORAES SALES, SP",
      "1604 TAQUARAL, SP",
      "1719 AV DA SAUDADE, SP",
      "2554 JUSTICA FEDERAL CAMPINAS, SP",
      "2722 SANASA CAMPINAS, SP",
      "2861 JARDIM DO TREVO, SP",
      "2883 SOUSAS, SP",
      "2886 SHOPPING UNIMART, SP",
      "2908 MIRANTE DO CASTELO, SP",
      "2952 NORTE-SUL, SP",
      "2966 BARAO GERALDO, SP",
      "3094 EMPRESARIAL CAMPINAS, SP",
      "3100 SAO QUIRINO, SP",
      "3720 DIGITAL CAMPINAS, SP",
      "3914 CAMBUI DE CAMPINAS, SP",
      "4004 AV DAS AMOREIRAS, SP",
      "4056 FORUM TRABALHISTA DE CAMPINAS, SP",
      "4057 TRT CAMPINAS, SP",
      "4073 VIA BRASIL, SP",
      "4083 BONFIM, SP",
      "4084 JOAO JORGE, SP",
      "4089 JARDIM OURO VERDE, SP",
      "4212 AQUIDABA, SP",
      "4272 CORPORATIVO CAMPINAS",
      "4487 PARQUE DOM PEDRO, SP",
      "4731 BARAO DE ITAPURA, SP",
      "4898 JARDIM LONDRES, SP",
      "4907 LARGO DO ROSARIO, SP",
      "4985 JULIO DE MESQUITA,SP"
    ],
    PAULINIA: ["3046 PAULINIA SHOPPING"],
    VINHEDO: ["1185 VINHEDO, SP"],
    LOUVEIRA: ["1350 LOUVEIRA, SP"],
    PAULINIA: ["0860 PAULINIA, SP3046 PAULINIA SHOPPING, SP"],
    PIRACICABA: ["0332 PIRACICABA, SP",
      "1397 JUSTICA DO TRABALHO PIRACICABA, SP",
      "2199 VILA REZENDE, SP",
      "2882 CIDADE ALTA, SP",
      "3008 AVENIDA CARLOS BOTELHO, SP",
      "3428 NOIVA DA COLINA, SP",
      "3870 DIGITAL PIRACICABA, SP",
      "3969 JUSTICA FEDERAL PIRACICABA, SP",
      "4104 PIRACICAMIRIM, SP",
      "4186 FUMEP, SP",
      "4225 SANTA TEREZINHA, SP",
      "4281 EMPRESARIAL PIRACICABA, SP",
      "4889 RUA MORAES BARROS, SP",
      "4899 ESTACAO PAULISTA, SP"
    ],
    JUNDIAI: ["0316 JUNDIAI, SP",
      "1452 PAINEIRAS, SP",
      "1600 PARQUE DA UVA, SP",
      "1883 VIANELO, SP",
      "2209 VILA ARENS, SP",
      "2950 TRF JUNDIAI, SP",
      "2968 SERRA DO JAPI, SP",
      "3104 EMPRESARIAL JUNDIAI, SP",
      "3197 PONTE SAO JOAO, SP",
      "4711 BARONESA DE JUNDIAI, SP",
      "4895 ELOY CHAVES, SP",
      "4906 VILA HORTOLANDIA, SP"
    ],
    "SÃO PAULO": [
      "0230 VILA ESPANHOLA, SP",
      "0235 SE, SP",
      "0236 ALTO DA MOOCA, SP",
      "0237 PORTO GERAL, SP",
      "0238 AV PAULISTA, SP",
      "0239 AUGUSTA, SP",
      "0240 BELA VISTA, SP",
      "0241 BELENZINHO, SP",
      "0242 BRAS, SP",
      "0243 CAMBUCI, SP",
      "0244 CASA VERDE, SP",
      "0245 VILLA LOBOS, SP",
      "0246 JOAO DE LUCA, SP",
      "0249 SETE DE ABRIL, SP",
      "0251 MOEMA, SP",
      "0252 IPIRANGA, SP",
      "0253 SENADOR QUEIROS, SP",
      "0254 ITAIM, SP",
      "0255 PRACA DA ARVORE, SP",
      "0256 RUA CLEMENTE ALVARES, SP",
      "0257 GUAICURUS, SP",
      "0259 MOOCA, SP",
      "0260 NOSSA SENHORA DO O, SP",
      "0261 M BOI MIRIM, SP",
      "0262 PENHA DE FRANCA, SP",
      "0263 PEDROSO DE MORAES, SP",
      "0265 JUSTICA FEDERAL SAO PAULO, SP",
      "0267 SANTA CECILIA, SP",
      "0268 SANTANA, SP",
      "0269 BORBA GATO, SP",
      "0270 TATUAPE, SP",
      "0271 MAZZEI, SP",
      "0272 VILA CARRAO, SP",
      "0273 VILA MARIA, SP",
      "0274 ALFONSO BOVERO, SP",
      "0275 VILA PRUDENTE, SP",
      "0538 PARADA INGLESA, SP",
      "0605 SAO MIGUEL PAULISTA, SP",
      "0612 BROOKLIN, SP",
      "0657 JARDIM DA SAUDE, SP",
      "0689 CONSOLACAO, SP",
      "0734 JARDIM PERI, SP",
      "0988 MONCOES, SP",
      "1002 AVENIDA LINS DE VASCONCELOS, SP",
      "1003 VITAL BRASIL, SP",
      "1004 SAO BENTO, SP",
      "1005 PERDIZES, SP",
      "1006 VILA FORMOSA, SP",
      "1007 VILA GUILHERME, SP",
      "1008 VILA MATILDE, SP",
      "1017 VIA ANCHIETA, SP",
      "1086 ITAQUERA, SP",
      "1087 HEITOR PENTEADO, SP",
      "1155 AV IBIRAPUERA, SP",
      "1166 PARI, SP",
      "1181 TRF 3A REGIAO SAO PAULO, SP",
      "1218 VILA NOVA CONCEICAO, SP",
      "1221 BUTANTA, SP1230 AROUCHE, SP",
      "1231 BARRA FUNDA, SP",
      "1234 PONTE RASA, SP",
      "1304 BARREIRA GRANDE, SP",
      "1349 ESTACAO SAO JOAQUIM, SP",
      "1360 JARDIM EUROPA, SP",
      "1365 CAMPO LIMPO, SP",
      "1367 JABAQUARA, SP",
      "1368 IMIRIM, SP",
      "1370 JARDIM PAULISTA, SP",
      "1371 VILA NOVA CACHOEIRINHA, SP",
      "1372 CLELIA, SP",
      "1374 METRO SANTA CRUZ, SP",
      "1376 VILA PARANAGUA, SP",
      "1412 SHOPPING D, SP",
      "1484 SAMPA, SP",
      "1531 AV CASPER LIBERO, SP",
      "1567 ITINGUCU, SP",
      "1571 PAES DE BARROS, SP",
      "1572 PARAISO, SP",
      "1597 CLINICAS, SP",
      "1598 GUARAPIRANGA, SP",
      "1601 VILA ALPINA, SP",
      "1602 JARDIM AEROPORTO, SP",
      "1603 PARQUE SAO JORGE, SP",
      "1609 PARQUE DA ACLIMACAO, SP",
      "1617 VIEIRA DE MORAES, SP",
      "1618 VILA DIVA, SP",
      "1635 VILA ESPERANCA, SP",
      "1652 HIGIENOPOLIS, SP",
      "1653 ITAIM PAULISTA, SP",
      "1654 PLANALTO PAULISTA, SP",
      "1655 SILVIO ROMERO, SP",
      "1656 CASA DE PEDRA, SP",
      "1679 CARLOS SAMPAIO, SP",
      "1772 NOSSA SENHORA DO GUADALUPE, SP",
      "1812 METRO OSCAR FREIRE, SP",
      "1813 FARIA LIMA, SP",
      "1816 GRANJA JULIETA, SP",
      "1906 TAJURAS, SP",
      "1907 ENGENHEIRO CAETANO, SP",
      "1934 PARQUE DO CARMO, SP",
      "1967 VILA FRIBURGO, SP",
      "1976 VILA EMA, SP",
      "2034 CONSELHEIRO CARRAO, SP",
      "2038 LAJEADO VELHO, SP",
      "2055 JARDIM GERMANIA, SP",
      "2097 INDIANOPOLIS, SP",
      "2103 JARDIM HELENA, SP",
      "2111 VILA CARMOSINA, SP",
      "2115 SAO GUALTER, SP",
      "2134 JARDIM MARINGA, SP",
      "2136 NOVA PARAISOPOLIS, SP",
      "2139 BARAO DE JUNDIAI, SP",
      "2167 JARDIM BONFIGLIOLI, SP",
      "2177 HEBRAICA, SP",
      "2189 NHAMBIQUARAS, SP",
      "2194 AV REPUBLICA DO LIBANO, SP",
      "2201 JARDIM NORMA, SP",
      "2212 BRIGADEIRO PAULISTA, SP",
      "2287 MIRANDOPOLIS, SP",
      "2297 AV IMPERADOR, SP",
      "2341 TAQUARICHIM, SP",
      "2353 JARDIM TIETE, SP",
      "2368 VILA LIVIERO, SP",
      "2472 CURSINO, SP",
      "2484 AGUA FRIA, SP",
      "2527 EXECUCOES FISCAIS, SP",
      "2766 JEF SAO PAULO, SP",
      "2852 POLICIA FEDERAL, SP",
      "2856 TRT 2A REGIAO, SP",
      "2862 BERRINI, SP",
      "2873 25 DE JANEIRO, SP",
      "2879 SHOPPING FREI CANECA, SP",
      "2880 NOVA TIETE, SP",
      "2887 ESTADOS UNIDOS, SP",
      "2888 JARDIM FRANCA, SP",
      "2899 BRIGADEIRO, SP",
      "2903 PRACA DA LIBERDADE, SP",
      "2911 RUA HENRIQUE SCHAUMANN, SP",
      "2924 ANALIA FRANCO, SP",
      "2925 CAMPO DE MARTE, SP",
      "2926 VILA OLIMPIA, SP",
      "2928 JOAO DIAS, SP",
      "2929 LARGO SAO MATEUS, SP",
      "2941 TURIASSU, SP",
      "2942 PARQUE DA MOOCA, SP",
      "2953 AVENIDA ZELINA, SP",
      "2962 IGUATEMI, SP",
      "2964 JARDIM BRASIL, SP",
      "2994 DOMINGOS DE MORAES, SP",
      "2995 MARACATINS, SP",
      "3006 BORGES LAGOA, SP",
      "3007 REAL PARQUE, SP",
      "3009 CIDADE SAO PAULO, SP",
      "3010 BRIGADEIRO LUIS ANTONIO-JARDINS, SP",
      "3011 FORUM RUY BARBOSA, SP",
      "3012 PARQUE SAO DOMINGOS, SP",
      "3019 SERRA DA CANTAREIRA, SP",
      "3032 AV BRASIL, SP3033 TUTOIA, SP",
      "3039 ALVARES PENTEADO, SP",
      "3045 NOVA AUGUSTA, SP",
      "3049 NOVA FARIA LIMA, SP",
      "3051 MONTEIRO DE MELO, SP",
      "3053 LIBERO BADARO, SP",
      "3055 CHACARA SANTO ANTONIO, SP",
      "3056 RUA DIREITA, SP",
      "3059 VILA DOS REMEDIOS, SP",
      "3080 CORPORATIVO INFRAESTRUTURA3088 CHACARA FLORA, SP",
      "3097 FUNCHAL, SP",
      "3099 NOVA PACAEMBU, SP",
      "3103 EMPRESARIAL IPIRANGA, SP",
      "3107 NOVA ACLIMACAO, SP",
      "3108 AVENIDA ORATORIO, SP",
      "3116 VICENTE RAO, SP",
      "3117 METRO SAUDE, SP",
      "3124 SAO JOAO CLIMACO, SP",
      "3128 VILA MANCHESTER, SP",
      "3149 BIXIGA, SP",
      "3154 EMPRESARIAL PAULISTA, SP",
      "3180 JARDIM GIANETTI, SP",
      "3188 SHOPPING PENHA, SP",
      "3191 METRO CONCEICAO, SP",
      "3193 VEREADOR JOSE DINIZ, SP",
      "3198 JARDIM IGUATEMI, SP",
      "3208 SERRA DE BRAGANCA, SP",
      "3211 LUIS GOIS, SP3216 GRAJAU, SP",
      "3217 SILVA TELES, SP",
      "3232 TAMANDARE, SP",
      "3237 ALBERTO BYINGTON, SP",
      "3243 AV JUSCELINO KUBITSCHEK, SP",
      "3256 PORTAL DO MORUMBI, SP",
      "3262 VILA ANASTACIO, SP",
      "3271 RAPOSO TAVARES, SP",
      "3277 VILA MADALENA, SP",
      "3278 AVENIDA FRANCISCO MATARAZZO, SP",
      "3280 JARDIM ANGELA, SP",
      "3284 AVENIDA DAS CEREJEIRAS, SP",
      "3287 ALTO DA PENHA, SP",
      "3288 BERNARDINO DE CAMPOS, SP",
      "3289 AGUIA DE HAIA, SP",
      "3291 CORIFEU DE AZEVEDO MARQUES, SP",
      "3306 SUMAREZINHO, SP",
      "3312 JARDIM SAO LUIZ, SP",
      "3317 AEROPORTO DE CONGONHAS, SP",
      "3324 AV CARLOS LACERDA, SP",
      "3325 VILA REGENTE FEIJO, SP",
      "3328 LARGO DE PINHEIROS, SP",
      "3329 VILA SOCORRO, SP",
      "3330 VILA MASCOTE, SP",
      "3337 CORPORATIVO SAO PAULO",
      "3341 RUA DOS ITALIANOS, SP",
      "3347 CINCINATO BRAGA, SP",
      "3365 ALTO DO TATUAPE, SP",
      "3396 USP, SP",
      "3579 PARELHEIROS, SP",
      "3582 PARADA DE TAIPAS, SP",
      "3610 DIGITAL SAO PAULO LESTE, SP",
      "3682 DIGITAL SAO PAULO CENTRO, SP",
      "3718 DIGITAL SAO PAULO NORTE, SP",
      "3744 MARQUES DE SAO VICENTE, SP",
      "3764 RUA PAULA SOUSA, SP",
      "3775 AVENIDA RANGEL PESTANA, SP",
      "3786 DIGITAL PAULISTA, SP",
      "3807 DIGITAL OSASCO, SP",
      "3809 JARDIM COLONIAL, SP",
      "3880 VAREJO DIGITAL SAO PAULO",
      "3994 NOSSA SENHORA DAS MERCES, SP",
      "4007 ARICANDUVA, SP",
      "4008 ARTUR ALVIM, SP",
      "4009 MATEO BEI, SP",
      "4010 INTERLAGOS, SP",
      "4011 SAPOPEMBA, SP",
      "4031 GUAIANASES, SP",
      "4032 PARQUE SAO LUCAS, SP",
      "4033 TUCURUVI, SP",
      "4038 RUA SCHILLING, SP",
      "4047 PARQUE NOVO MUNDO, SP",
      "4048 ERMELINO MATARAZZO, SP",
      "4049 PIRITUBA, SP",
      "4050 BELAS ARTES, SP",
      "4051 AYRTON SENNA, SP",
      "4054 ADONIRAN BARBOSA, SP",
      "4055 PARAPUA, SP4067 CANGAIBA, SP",
      "4069 LIMAO, SP",
      "4070 PRACA DA REPUBLICA, SP",
      "4071 CAPAO REDONDO, SP",
      "4072 AV SANTA CATARINA, SP",
      "4074 MANDAQUI, SP",
      "4076 VILA SONIA, SP",
      "4077 BOM RETIRO, SP",
      "4085 JAGUARE, SP",
      "4094 CURUCA, SP",
      "4105 VILA SABRINA, SP",
      "4116 LARGO 13 DE MAIO, SP",
      "4125 MORUMBI, SP",
      "4126 PERUS, SP",
      "4128 DR ZUQUIM, SP",
      "4130 NOVA GRANADA, SP",
      "4134 JARDIM SUL, SP",
      "4135 NOVA FIESTA, SP",
      "4136 TIBURCIO DE SOUZA, SP",
      "4138 AV RIO PEQUENO, SP",
      "4139 SP MARKET, SP",
      "4141 PACAEMBU, SP",
      "4142 NOSSA SENHORA SABARA, SP",
      "4150 PRACA ITAQUERA, SP",
      "4154 SHOPPING INTERLAR ARICANDUVA, SP",
      "4155 CENTRO EMPRESARIAL, SP",
      "4158 METRO ANA ROSA, SP",
      "4159 CENTRAL PLAZA SHOPPING, SP",
      "4241 CONJUNTO NACIONAL SAO PAULO, SP",
      "4278 EMPRESARIAL PENHA, SP",
      "4280 EMPRESARIAL PINHEIROS, SP",
      "4284 EMPRESARIAL SANTO AMARO, SP",
      "4286 EMPRESARIAL SE, SP",
      "4530 CIDADE DUTRA, SP",
      "4531 JARDIM DAS IMBUIAS, SP",
      "4532 FILHOS DA TERRA, SP",
      "4533 VALO VELHO, SP",
      "4557 VILA JOANIZA, SP",
      "4561 MUSEU DO IPIRANGA, SP",
      "4573 LAUZANE, SP",
      "4615 JARDIM AVELINO, SP",
      "4616 PATRIOTAS, SP",
      "4617 PARQUE DO ESTADO, SP",
      "4619 METRO SAO JUDAS, SP",
      "4631 CIDADE ADEMAR, SP",
      "4632 JARDIM CAMARGO NOVO, SP",
      "4633 CORIOLANO, SP",
      "4634 VILA JACUI, SP",
      "4636 CLODOMIRO AMAZONAS, SP",
      "4645 RUA VITORIA, SP",
      "4677 AV CELSO GARCIA, SP",
      "4679 MEMORIAL, SP",
      "4681 LARGO DO CAPAO, SP",
      "4712 COTOVIA, SP",
      "4713 AV VEREADOR ABEL FERREIRA, SP",
      "4715 RUA TOBIAS BARRETO, SP",
      "4716 JARDIM JAPAO, SP",
      "4717 PARQUE BOTURUSSU, SP",
      "4720 JARDIM ESTHER, SP",
      "4777 ORFANATO, SP",
      "4778 PRACA DO FORRO, SP",
      "4779 AV RIO DAS PEDRAS, SP",
      "4788 ALTO DO IPIRANGA, SP",
      "4789 FAZENDA DA JUTA, SP",
      "4790 VILA CLEMENTINO, SP",
      "4793 MACUNIS, SP",
      "4843 HELIOPOLIS, SP",
      "4851 MOOCA PLAZA SHOPPING, SP",
      "4853 PAULISTA SAO LUIS, SP",
      "4905 PAMPLONA, SP",
      "4988 BRASILANDIA, SP",
      "4990 ALTO DO JARAGUA, SP",
      "4992 CORPORATIVO CONSTRUCAO CIVIL",
      "4994 IBITIRAMA, SP"
    ],
    "SANTA BÁRBARA D'OESTE": ["0960 - STA BARBARA D'OESTE "],
    SUMARE: ["0961 SUMARE, SP",
      "2356 DEZESSEIS DE DEZEMBRO, SP",
      "2885 ANHANGUERA DE SUMARE, SP",
      "3503 ORQUIDEA DE SUMARE, SP",
      "4177 PREF MUNICIPAL SUMARE, SP"
    ],
    VALINHOS: ["0363 VALINHOS, SP"]
  }
};

export default agenciasCaixaEstadoCidade;
