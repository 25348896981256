const tiposOcupacaoImovel = [
  "",
  "PRÓPRIA QUITADA",
  "PRÓPRIA FINANCIADA CAIXA",
  "PRÓPRIA FINANCIADA OUTRAS ENTIDADES",
  "ALUGADA",
  "MORA COM PAIS/PARENTES",
  "CEDIDA",
  "FUNCIONAL",
  "OUTROS"
];

export default tiposOcupacaoImovel;
