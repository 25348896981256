import Vue from "vue";

const state = {};

// getters
const getters = {};

// mutations
const mutations = {};

// actions
const actions = {
  // eslint-disable-next-line no-empty-pattern
  async generateReport({}, filter) {
    const axios = await Vue.$axios(false);
    const params = {};

    if (filter.from) {
      params.from = filter.from;
    }

    if (filter.to) {
      params.to = filter.to;
    }

    return new Promise((resolve, reject) => {
      axios
        .get("/reports", { params: params })
        .then(value => {
          resolve(value.data.data);
        })
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async exportReport({}, filter) {
    const axios = await Vue.$axios(false);
    const params = {};

    if (filter.from) {
      params.from = filter.from;
    }

    if (filter.to) {
      params.to = filter.to;
    }

    return new Promise((resolve, reject) => {
      axios
        .get("/reports/export", { params: params })
        .then(value => {
          resolve(value.data.data);
        })
        .catch(error => reject(error));
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
