import { createHelpers, getField, updateField } from "vuex-map-fields";
import _ from "lodash";
import { ulid } from "ulid";

// Ref:
//   - https://markus.oberlehner.net/blog/how-to-structure-a-complex-vuex-store/
//   - https://github.com/maoberlehner/vuex-map-fields#vuex-map-fields
//   - https://github.com/robinvdvleuten/vuex-persistedstate

export const clienteEmpty = Object.freeze({
  protocolo: "00000000",
  codCorrespondente: "000000000",
  ordem: null,
  cpf: null,
  nomeCompleto: null,
  nomeReduzido: null,
  declaracaoPropositos: {
    movimentacaoContaDepositoPoupanca: false,
    emprestimosFinanciamentos: false,
    financiamentoHabitacional: false,
    investimentos: false,
    cartaoCredito: false,
    seguroPrevidenciaCapitalizacaoConsorcios: false,
    operacoesInternacionaisCambio: false
  },
  dataNascimento: null,
  sexo: null,
  nacionalidade: null,
  pais: null,
  naturalidade: { uf: null, municipio: null },
  nomePai: null,
  nomeMae: null,
  grauInstrucao: null,
  pisNis: null,
  tipoDocumento: {
    value: null,
    numero: null,
    orgaoEmissor: null,
    uf: null,
    dataEmissao: null,
    dataFimValidade: null,
    dataPrimeiraHabilitacao: null,
    documentoIdentificacao: {
      subTipo: null
    },
    passaporte: {
      paisOrigem: null
    }
  },
  estadoCivil: null,
  tipoOcupacao: null,
  ocupacao: null,
  conjuge: {
    cpf: null,
    nome: null
  },
  endereco: {
    cep: null,
    tipoLogradouro: null,
    logradouro: null,
    complemento: null,
    numero: null,
    bairro: null,
    uf: null,
    cidade: null,
    tipoImovel: null,
    ocupacaoImovel: null,
    comprovanteResidenciaMes: null,
    comprovanteResidenciaAno: null
  },
  telefones: {
    contato: { ddd: null, telefone: null },
    celular: { ddd: null, telefone: null },
    recado: { ddd: null, telefone: null }
  },
  email: null,
  rendasFormais: [],
  rendasInformais: [],
  respostaAssino: [],
  patrimonioConsolidado: {
    valorPatrimonio: null
  },
  agenciaRelacionamento: {
    uf: null,
    municipio: null,
    codigoNomeAgencia: null
  }
});

// initial state
const state = {
  cliente: _.cloneDeep(clienteEmpty),
  clientes: [],
  readOnlyDossie: null
};

// getters
const getters = {
  cliente(state) {
    return state.cliente;
  },
  getField,
  getClienteField(state) {
    return getField(state.cliente);
  }
};

// mutations
const mutations = {
  updateField,
  updateClienteField(state, field) {
    updateField(state.cliente, field);
  },
  // RENDA FORMAL
  addRendaFormal(state, renda) {
    addToList(state.cliente.rendasFormais, renda);
  },
  removeRendaFormal(state, renda) {
    state.cliente.rendasFormais = removeFromList(state.cliente.rendasFormais, renda);
  },
  // RENDA INFORMAL
  addRendaInformal(state, renda) {
    addToList(state.cliente.rendasInformais, renda);
  },
  removeRendaInformal(state, renda) {
    state.cliente.rendasInformais = removeFromList(state.cliente.rendasInformais, renda);
  }
};

// actions
const actions = {
  newCliente({ commit, state }) {
    return new Promise(resolve => {
      commit("updateField", { path: "cliente", value: _.cloneDeep(clienteEmpty) });
      resolve(state.cliente);
    });
  },
  setCliente({ commit, state }, cliente) {
    return new Promise(resolve => {
      commit("updateField", { path: "cliente", value: _.merge(_.cloneDeep(clienteEmpty), cliente) });
      resolve(state.cliente);
    });
  },
  setClientes({ commit, state }, clientes) {
    return new Promise(resolve => {
      commit("updateField", { path: "clientes", value: clientes });
      resolve(state.clientes);
    });
  },
  setReadOnlyDossie({ commit, state }, dossie) {
    return new Promise(resolve => {
      commit("updateField", { path: "readOnlyDossie", value: dossie });
      resolve(state.readOnlyDossie);
    });
  },
  changeClienteIfExists({ state, dispatch }, cpf) {
    return new Promise(resolve => {
      const cliente = state.clientes.find(r => r.cpf === cpf);
      if (cliente) {
        dispatch("setCliente", cliente).then(value => resolve(value));
      } else {
        resolve(null);
      }
    });
  }
};

// FUNCTIONS
function addToList(list, value) {
  if (!value.uid) {
    list.push({ uid: ulid(), ...value });
  } else {
    const item = list.find(r => r.uid === value.uid);
    Object.assign(item, value);
  }
}

function removeFromList(list, value) {
  return _.filter(list, r => r.uid !== value.uid);
}

// EXPORTS
export const { mapFields: mapClienteFields } = createHelpers({
  getterType: "caixaquiClientes/getClienteField",
  mutationType: "caixaquiClientes/updateClienteField"
});

// EXPORTS
export const { mapFields: mapCliente } = createHelpers({
  getterType: "caixaquiClientes/getField"
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
