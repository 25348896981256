import Swal from "sweetalert2/dist/sweetalert2";
import _ from "lodash";
import "sweetalert2/src/sweetalert2.scss";
// import "@sweetalert2/theme-bootstrap-4/bootstrap-4.scss";

const defaultMsg = "Sistema indisponível no momento!";

class Alert {
  _vue;

  constructor(vue) {
    this._vue = vue;
  }

  notifySuccess(msg) {
    // noinspection JSUnresolvedFunction
    return Swal.fire({
      toast: true,
      position: "top-end",
      type: "success",
      title: msg,
      showConfirmButton: false,
      timer: 3000
    });
  }

  confirmation(title, text) {
    return Swal.fire({
      title: title,
      text: text,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1b8418",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    });
  }

  confirmDelete(title, text) {
    return Swal.fire({
      title: title,
      text: text,
      type: "error",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonText: "Cancelar"
    });
  }

  warn(msg) {
    Swal.fire({
      title: "Atenção",
      type: "warning",
      confirmButtonText: "Continuar",
      html: msg
    });
  }

  error(errorData, additionalMsg) {
    try {
      if (_.isString(errorData)) {
        this._fireTextError(errorData);
      } else if (!_.isNil(errorData) && !_.isEmpty(errorData.errors)) {
        let msg = this._buildError(errorData.errors);

        if (!_.isNil(additionalMsg)) {
          msg = `<p>${msg}</p>${additionalMsg}`;
        }

        this._fireTextError(msg);
      } else {
        this._fireTextError();
      }
    } catch (e) {
      this._fireTextError();
    }
  }

  // noinspection JSMethodCanBeStatic
  _fireTextError(msg = defaultMsg) {
    Swal.fire({
      title: "Oops...",
      type: "error",
      confirmButtonText: "Continuar",
      html: msg
    });
  }
}

export default {
  // eslint-disable-next-line no-unused-vars
  install(vue, opts) {
    vue.$alert = new Alert(vue);
    vue.prototype.$alert = vue.$alert;
  }
};
