const subTipoDocumentoIdentificacao = [
  { value: "", orgaoEmissores: [] },
  {
    value: "CARTEIRA DE IDENTIDADE",
    orgaoEmissores: [
      "",
      "SECRETARIA DE SEGURANÇA PÚBLICA",
      "OUTRO TIPO DE ÓRGÃO EMISSOR",
      "SECRETARIA DEFESA SOCIAL",
      "POLÍCIA CIVIL",
      "SECRETARIA DE ESTADO DA CASA CIVIL"
    ]
  },
  {
    value: "CARTEIRA FUNCIONAL",
    orgaoEmissores: [
      "",
      "CONSELHO REGIONAL DE ADMINISTRAÇÃO",
      "CONSELHO REGIONAL DE ASSISTÊNCIA SOCIAL",
      "CONSELHO REGIONAL DE BIBLIOTECONOMIA",
      "CONSELHO REGIONAL DE CONTABILIDADE",
      "CONSELHO REGIONAL DE ENFERMAGEM",
      "CONSELHO REGIONAL DE ENGENHARIA E AGRONOMIA",
      "CONSELHO REGIONAL DE ESTATÍSTICA",
      "CONSELHO REGIONAL DE MEDICINA",
      "CONSELHO REGIONAL DE MEDICINA VETERINÁRIA",
      "ORDEM DOS MÚSICOS DO BRASIL",
      "CONSELHO REGIONAL DE ODONTOLOGIA",
      "CONSELHO REGIONAL DE PROFISSIONAIS DE RELAÇÕES PÚBLICAS",
      "ORDEM DOS ADVOGADOS DO BRASIL",
      "CONSELHO REGIONAL DE ECONOMIA",
      "CONSELHO REGIONAL DE ECONOMIA DOMÉSTICA",
      "CONSELHO REGIONAL DO MUSEÓLOGO",
      "CONSELHO REGIONAL DOS JORNALISTAS",
      "CONSELHO DE ARQUITETURA E URBANISMO DO BRASIL",
      "OUTRO TIPO DE ÓRGÃO EMISSOR DE CARTEIRA FUNCIONAL",
      "MINISTÉRIO DA AERONÁUTICA – CF",
      "MINISTÉRIO DO EXÉRCITO – CF",
      "MINISTÉRIO DA MARINHA – CF"
    ]
  },
  {
    value: "IDENTIDADE MILITAR",
    orgaoEmissores: [
      "",
      "MINISTÉRIO DA AERONÁUTICA",
      "MINISTÉRIO DO EXÉRCITO",
      "MINISTÉRIO DA MARINHA",
      "OUTRO TIPO DE ÓRGÃO EMISSOR DE IDENTIDADE MILITAR"
    ]
  },
  {
    value: "CARTEIRA DE IDENTIDADE ESTRANGEIRA",
    orgaoEmissores: ["", "POLÍCIA FEDERAL", "OUTRO TIPO DE ÓRGÃO EMISSOR DE CARTEIRA ESTRANGEIRO"]
  }
];

const tiposDocumentos = [
  { value: "" },
  { value: "DOCUMENTO DE IDENTIFICAÇÃO", subTipos: subTipoDocumentoIdentificacao },
  {
    value: "RIC - REGISTRO DE IDENTIDADE CIVIL",
    orgaoEmissores: ["", "MINISTÉRIO DA JUSTIÇA", "OUTRO TIPO DE ÓRGÃO EMISSOR DE RIC"]
  },
  { value: "PASSAPORTE", orgaoEmissores: ["", "POLÍCIA FEDERAL", "ÓRGÃO EMISSOR ESTRANGEIRO"] },
  { value: "CNH - CARTEIRA NACIONAL DE HABILITAÇÃO", orgaoEmissores: ["ÓRGÃO DE TRÂNSITO"] }
];

export default tiposDocumentos;
