import ROUTERS from "./router-constants";
import caixaAquiRouter from "./caixaqui";
import assinoRouter from "./assino";
import Home from "../views/Home";

function addMetaRequired(route) {
  route.meta = {
    ...route.meta,
    requiresAuth: true
  };

  if (route.children) {
    route.children.forEach(childRoute => addMetaRequired(childRoute));
  }

  return route;
}

const privateRouter = [
  {
    path: ROUTERS.home.path,
    name: ROUTERS.home.name,
    component: Home
  },
  {
    path: ROUTERS.profile.path,
    name: ROUTERS.profile.name,
    component: () => import(/* webpackChunkName: "profile" */ "../views/Profile"),
    meta: { requiresAdminRole: true }
  },
  {
    path: ROUTERS.dossies.path,
    name: ROUTERS.dossies.name,
    component: () => import(/* webpackChunkName: "profile" */ "../views/dossie/DossieList"),
    meta: { requiresAdminRole: true }
  },
  {
    path: ROUTERS.dossiesUpload.path,
    name: ROUTERS.dossiesUpload.name,
    component: () => import(/* webpackChunkName: "profile" */ "../views/dossie/DossieUpload"),
    meta: { requiresAdminRole: true }
  },
  {
    path: ROUTERS.dossie.path,
    name: ROUTERS.dossie.name,
    component: () => import(/* webpackChunkName: "profile" */ "../views/dossie/DossieItem"),
    meta: { requiresAdminRole: true }
  },
  {
    path: ROUTERS.perfil.path,
    name: ROUTERS.perfil.name,
    component: () => import(/* webpackChunkName: "profile" */ "../views/perfil/Perfil")
  },
  {
    path: ROUTERS.treinamento.path,
    name: ROUTERS.treinamento.name,
    component: () => import(/* webpackChunkName: "profile" */ "../views/treinamento/TreinamentoItem")
  },
  {
    path: ROUTERS.correcao.path,
    name: ROUTERS.correcao.name,
    component: () => import(/* webpackChunkName: "profile" */ "../views/correcao/CorrecaoList")
  },
  {
    path: ROUTERS.correcaoItem.path,
    name: ROUTERS.correcaoItem.name,
    component: () => import(/* webpackChunkName: "profile" */ "../views/correcao/CorrecaoItem")
  },
  {
    path: ROUTERS.relatorio.path,
    name: ROUTERS.relatorio.name,
    component: () => import(/* webpackChunkName: "profile" */ "../views/relatorio/Relatorio")
  },
  ...caixaAquiRouter,
  ...assinoRouter
].map(route => addMetaRequired(route));

export default privateRouter;
