/* eslint-disable max-len */
import ROUTERS from "./router-constants";

const caixaAquiAvaliacaoRiscoRouter = [
  {
    // AVALIACAO RISCO
    path: ROUTERS.caixaqui.avaliacaoRiscos.path,
    name: ROUTERS.caixaqui.avaliacaoRiscos.name,
    component: () =>
      import(/* webpackChunkName: "caixaqui.avaliacao-riscos.main" */ "../views/caixaqui/CaixaquiFormMain"),
    children: [
      {
        // DADOS TOMADOR
        path: ROUTERS.caixaqui.avaliacaoRiscos.dadosTomador.path,
        name: ROUTERS.caixaqui.avaliacaoRiscos.dadosTomador.name,
        meta: {
          title: "Avaliação de Riscos",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          voltarLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          proximoLink: { name: ROUTERS.caixaqui.avaliacaoRiscos.dadosParticipante.name }
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.avaliacao-riscos.dados-tomador" */ "../views/caixaqui/avaliacao-riscos/CaixaquiAvaliacaoRiscosDadosTomador"
          )
      },
      {
        // DADOS PARTICIPANTE
        path: ROUTERS.caixaqui.avaliacaoRiscos.dadosParticipante.path,
        name: ROUTERS.caixaqui.avaliacaoRiscos.dadosParticipante.name,
        meta: {
          title: "Avaliação de Riscos",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          voltarLink: { name: ROUTERS.caixaqui.avaliacaoRiscos.dadosTomador.name },
          proximoLink: { name: ROUTERS.caixaqui.avaliacaoRiscos.informacoesOperacao.name }
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.avaliacao-riscos.dados-participante" */ "../views/caixaqui/avaliacao-riscos/CaixaquiAvaliacaoRiscosDadosParticipante"
          )
      },
      {
        // INFORMACOES DA OPERACAO
        path: ROUTERS.caixaqui.avaliacaoRiscos.informacoesOperacao.path,
        name: ROUTERS.caixaqui.avaliacaoRiscos.informacoesOperacao.name,
        meta: {
          title: "Avaliação de Riscos",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          voltarLink: { name: ROUTERS.caixaqui.avaliacaoRiscos.dadosParticipante.name },
          proximoLink: { name: ROUTERS.caixaqui.avaliacaoRiscos.autofinanciamento.name }
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.avaliacao-riscos.informacoes-operacao" */ "../views/caixaqui/avaliacao-riscos/CaixaquiAvaliacaoRiscosInformacoesOperacao"
          )
      },
      {
        // AUTOFINANCIAMENTO
        path: ROUTERS.caixaqui.avaliacaoRiscos.autofinanciamento.path,
        name: ROUTERS.caixaqui.avaliacaoRiscos.autofinanciamento.name,
        meta: {
          title: "Avaliação de Riscos",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          voltarLink: { name: ROUTERS.caixaqui.avaliacaoRiscos.informacoesOperacao.name },
          proximoLink: { name: ROUTERS.caixaqui.avaliacaoRiscos.produtosSelecionados.name }
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.avaliacao-riscos.autofinanciamento" */ "../views/caixaqui/avaliacao-riscos/CaixaquiAvaliacaoRiscosAutofinanciamento"
          )
      },
      {
        // PRODUTOS SELECIONADOS
        path: ROUTERS.caixaqui.avaliacaoRiscos.produtosSelecionados.path,
        name: ROUTERS.caixaqui.avaliacaoRiscos.produtosSelecionados.name,
        meta: {
          title: "Avaliação de Riscos",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          voltarLink: { name: ROUTERS.caixaqui.avaliacaoRiscos.autofinanciamento.name },
          proximoLink: null
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.avaliacao-riscos.produtosSelecionados" */ "../views/caixaqui/avaliacao-riscos/CaixaquiAvaliacaoRiscosProdutosSelecionados"
          )
      },
      {
        // PRODUTOS SELECIONADOS PROPOSTA
        path: ROUTERS.caixaqui.avaliacaoRiscos.produtosSelecProposta.path,
        name: ROUTERS.caixaqui.avaliacaoRiscos.produtosSelecProposta.name,
        meta: {
          title: "Avaliação de Riscos",
          menuLink: { name: ROUTERS.caixaqui.menus.negocios.name },
          voltarLink: { name: ROUTERS.caixaqui.avaliacaoRiscos.produtosSelecionados.name },
          proximoLink: null
        },
        component: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "caixaqui.avaliacao-riscos.produtosSelecionados" */ "../views/caixaqui/avaliacao-riscos/CaixaquiAvaliacaoRiscosProdutosSelecProposta"
          )
      }
    ]
  }
];

export default caixaAquiAvaliacaoRiscoRouter;
