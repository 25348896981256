import Callback from "../components/Callback";
import Forbidden from "../views/errors/Forbidden";
import NotFound from "../views/errors/NotFound";
import ROUTERS from "./router-constants";

const publicRouter = [
  {
    path: ROUTERS.callback.path,
    name: ROUTERS.callback.name,
    component: Callback
  },
  {
    path: ROUTERS.forbidden.path,
    name: ROUTERS.forbidden.name,
    component: Forbidden
  },
  {
    path: ROUTERS.notFound.path,
    name: ROUTERS.notFound.name,
    component: NotFound
  },
  {
    path: "*",
    redirect: ROUTERS.notFound.path
  }
];

export default publicRouter;
