import axios from "axios";
import Vue from "vue";
import auth0 from "../api/auth0";

class Axios {
  _vue;
  _axios;
  _get;
  _loader;
  _loaderCount;

  constructor(vue) {
    this._vue = vue;
    this._axios = axios.create({
      baseURL: process.env.VUE_APP_API_PATH,
      timeout: process.env.VUE_APP_REQUEST_TIMEOUT,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "Accept-Type": "application/json"
      }
    });
    this._loaderCount = [];
    this._axios.interceptors.request.use(config => {
      this.showLoading();
      return config;
    });

    this._axios.interceptors.response.use(
      response => {
        this.hideLoading();
        return response;
      },
      error => {
        this.hideLoading();
        try {
          this._vue.$log.error(error);
          if (error.response.data.status === "WARNING") {
            this._vue.$alert.warn(error.response.data.message);
          } else {
            this._vue.$alert.error(error.response.data.message);
          }
          return Promise.reject(error.response.data);
        } catch (e) {
          this._vue.$alert.error(error);
          return Promise.reject(error);
        }
      }
    );

    this._get = (loader = true) => {
      this._loaderCount.unshift(loader);
      return new Promise(resolve => {
        auth0.getAccessToken().then(accessToken => {
          this._axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
          resolve(this._axios);
        });
      });
    };
  }

  hideLoading() {
    if (this._loaderCount.length > 0) {
      const showLoader = this._loaderCount.pop();

      if (showLoader && this._loader) {
        this._loader.hide();
        this._loader = null;
      }
    }
  }

  showLoading() {
    if (this._loaderCount.length > 0) {
      if (!this._loader && this._loaderCount[0]) {
        this._loader = Vue.$loading.show();
      }
    }
  }
}

export default {
  // eslint-disable-next-line no-unused-vars
  install(vue, opts) {
    vue.$axios = new Axios(vue)._get;
    vue.prototype.$axios = vue.$axios;
  }
};
