<template>
  <v-navigation-drawer :value="opened" @input="sideMenuClick" app :clipped="clipped">
    <v-list dense>
      <v-list-item v-for="(item, i) in menu" :key="i" :to="item.to">
        <v-list-item-action>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase" v-text="item.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SideMenu",
  props: ["opened", "clipped"],
  data: () => ({}),
  computed: {
    ...mapGetters("menu", ["menuItemsByRoles"]),
    ...mapGetters("auth", ["roles"]),
    menu() {
      return this.menuItemsByRoles(this.roles);
    }
  },
  methods: {
    sideMenuClick(e) {
      this.$emit("side-menu-click", e);
    }
  }
};
</script>

<style scoped></style>
