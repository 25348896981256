import Vue from "vue";

// initial state
const state = {
  formulario: null
};

// getters
const getters = {};

// mutations
const mutations = {};

// actions
const actions = {
  // eslint-disable-next-line no-empty-pattern
  async loadFormularios({}, cpf) {
    const axios = await Vue.$axios(false);
    const params = {};

    if (cpf) {
      params.id = cpf;
    }

    console.log("params: " + params);

    return new Promise((resolve, reject) => {
      axios
        .get("/formularios", { params: params })
        .then(value => {
          resolve(value.data.data);
        })
        .catch(error => reject(error));
    });
  },
  setFormulario({ commit, state }, formulario) {
    return new Promise(resolve => {
      commit("updateField", { path: "formulario", value: formulario });
      resolve(state.formulario);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
