import Vue from "vue";
import App from "./App.vue";
import router from "./routes";
import store from "./store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import VueLogger from "vuejs-logger";
import { LOG_OPTIONS, OPTIONS } from "./config";
import AuthPlugin from "./plugins/auth";
import ForwardPlugin from "./plugins/forward";
import Axios from "./plugins/axios";
import Vuelidate from "vuelidate";
import Loading from "vue-loading-overlay";
import Alert from "./plugins/alert";
import "vue-loading-overlay/dist/vue-loading.css";
import VueCurrencyInput from "vue-currency-input";
import VueTheMask from "vue-the-mask";

Vue.config.productionTip = false;

Vue.use(VueLogger, LOG_OPTIONS);
Vue.use(AuthPlugin);
Vue.use(Axios);
Vue.use(ForwardPlugin);
Vue.use(Vuelidate);
Vue.use(Loading);
Vue.use(Alert);
Vue.use(VueCurrencyInput, { globalOptions: OPTIONS.currency });
Vue.use(VueTheMask);

new Vue({
  router: router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
