import { tokens } from "vue-the-mask";

const isProduction = process.env.NODE_ENV === "production";

export const CONFIG = Object.freeze({
  isProd: isProduction,
  title: process.env.VUE_APP_TITLE,
  version: process.env.VUE_APP_VERSION
});

export const LOG_OPTIONS = Object.freeze({
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
});

export const OPTIONS = Object.freeze({
  currency: {
    currency: undefined,
    locale: "pt-BR",
    autoDecimalMode: true,
    decimalLength: undefined,
    distractionFree: false,
    min: null,
    max: null
  },
  dateFormat: "DD/MM/YYYY",
  masks: {
    cpf: "###.###.###-##",
    rg: {
      mask: "##.###.###-F",
      tokens: {
        ...tokens,
        F: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() }
      }
    },
    cnpj: "##.###.###/####-##",
    pis: "###########",
    cnh: "###########",
    cep: "#####-###",
    phone: {
      ddd: "##",
      number1: "####-####",
      number2: "#####-####"
    }
  }
});
