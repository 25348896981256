const ROUTERS = Object.freeze({
  home: { name: "home", path: "/" },
  callback: { name: "callback", path: "/callback" },
  forbidden: { name: "403", path: "/403" },
  notFound: { name: "404", path: "/404" },
  profile: { name: "profile", path: "/profile" },

  dossies: { name: "dossies", path: "/dossies" },
  dossiesUpload: { name: "dossies-upload", path: "/dossies/upload" },
  dossie: { name: "dossie", path: "/dossies/:id?" },
  perfil: { name: "perfil", path: "/perfil" },
  treinamento: { name: "treinamento", path: "/treinamentos/:id?" },
  correcao: { name: "corrigir", path: "/correcao" },
  correcaoItem: { name: "corrigir-item", path: "/correcao/:id" },
  relatorio: { name: "relatorio", path: "/relatorios" },

  assino:
  {
    name: "assino",
    path: "/assino/resposta-assino"
  },
  caixaqui: {
    name: "caixaqui",
    path: "/treinamentos/caixaqui",
    menus: {
      name: "caixaqui-menu",
      path: "menu",
      principal: { name: "caixaqui-menu-principal", path: "principal" },
      servicoCliente: { name: "caixaqui-menu-servico-ao-cliente", path: "servico-cliente" },
      negocios: { name: "caixaqui-menu-negocios", path: "negocios" }
    },
    cadastroClientes: {
      name: "caixaqui-cadastro-clientes",
      path: "cadastro-clientes",
      cpfCliente: { name: "caixaqui-cc-cpf-cliente", path: "cpf-cliente" },
      dadosCadastrais: { name: "caixaqui-cc-dados-cadastrais", path: "dados-cadastrais" },
      endereco: { name: "caixaqui-cc-endereco", path: "contato" },
      rendaFormal: { name: "caixaqui-cc-renda-formal", path: "renda-formal" },
      rendaInformal: { name: "caixaqui-cc-renda-informal", path: "renda-informal" },
      patrimonioConsolidado: {
        name: "caixaqui-cc-patrimonio-consolidado",
        path: "patrimonio-consolidado"
      },
      agenciaRelacionamentoDefinidaCliente: {
        name: "caixaqui-cc-agencia-relacionamento-definida-cliente",
        path: "agencia-relacionamento-definida-cliente"
      },
      resumo: {
        name: "caixaqui-cc-resumo",
        path: "resumo"
      }
    },
    avaliacaoRiscos: {
      name: "caixaqui-avaliacao-riscos",
      path: "avaliacao-riscos",
      dadosTomador: { name: "caixaqui-ar-dados-tomador", path: "dados-tomador" },
      dadosParticipante: { name: "caixaqui-ar-dados-participante", path: "dados-participante" },
      informacoesOperacao: { name: "caixaqui-ar-informacoes-operacao", path: "informacoes-operacao" },
      autofinanciamento: { name: "caixaqui-ar-autofinanciamento", path: "autofinanciamento" },
      produtosSelecionados: { name: "caixaqui-ar-produtosSelecionados", path: "produtosSelecionados" },
      produtosSelecProposta: { name: "caixaqui-ar-produtosSelecProposta", path: "produtosSelecProposta" }
    },
    formulariosEnviados: {
      name: "caixaqui-formularios-enviados",
      path: "formularios-enviados"
    },
    formulariosEnviadosAprovados: {
      name: "caixaqui-formularios-enviados-aprovados",
      path: "formularios-enviados-aprovados"
    },
    formulariosEnviadosReprovados: {
      name: "caixaqui-formularios-enviados-reprovados",
      path: "formularios-enviados-reprovados"
    },
    formulariosEnviadosCondicionados: {
      name: "caixaqui-formularios-enviados-condicionados",
      path: "formularios-enviados-condicionados"
    }
  }
});

export default ROUTERS;
