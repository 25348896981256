<template>
  <v-container class="fill-height" fluid>
    <v-card class="mx-auto d-inline-flex pa-2 justify-center align-center" height="150" outlined tile>
      <span class="display-3 font-weight-black">403</span>
      <span class="mx-5 display-1 font-weight-medium">-</span>
      <span class="display-1 font-weight-medium">Sem permissão para acessar este recurso</span>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Forbidden"
};
</script>
