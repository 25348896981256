import { createHelpers, getField, updateField } from "vuex-map-fields";
import _ from "lodash";
import { ulid } from "ulid";

// Ref:
//   - https://markus.oberlehner.net/blog/how-to-structure-a-complex-vuex-store/
//   - https://github.com/maoberlehner/vuex-map-fields#vuex-map-fields
//   - https://github.com/robinvdvleuten/vuex-persistedstate

export const avaliacaoRiscoEmpty = Object.freeze({
  cpf: null,
  nomeCompleto: null,
  produtos: {
    creditoImobiliario: false,
    consignacao: false,
    cartaoCredito: false,
    contaChequeAzul: false,
    consigPortabilidade: false,
    chequeAzulIsolado: false
  },
  origemRecurso: {
    valor: null,
    modalidade: null,
    produto: null,
    convenio: null,
    prazoTotal: null,
    valorImovel: null,
    prestNecessaria: null,
    valorFinanciamento: null,
    garantia: null,
    portabilidade: null,
    sistemaAmortizacao: null,
    indexador: null
  },
  participantes: [],
  autoFinanciamento: {
    cnpjConstrutora: null,
    prestacoes: [
      {
        numero: 1,
        dataVencimento: null,
        dataPagamento: null,
        valorPrestacao: null
      },
      {
        numero: 2,
        dataVencimento: null,
        dataPagamento: null,
        valorPrestacao: null
      },
      {
        numero: 3,
        dataVencimento: null,
        dataPagamento: null,
        valorPrestacao: null
      },
      {
        numero: 4,
        dataVencimento: null,
        dataPagamento: null,
        valorPrestacao: null
      },
      {
        numero: 5,
        dataVencimento: null,
        dataPagamento: null,
        valorPrestacao: null
      },
      {
        numero: 6,
        dataVencimento: null,
        dataPagamento: null,
        valorPrestacao: null
      }
    ]
  }
});

// initial state
const state = {
  avaliacaoRisco: _.cloneDeep(avaliacaoRiscoEmpty)
};

// getters
const getters = {
  avaliacaoRisco(state) {
    return state.avaliacaoRisco;
  },
  getField,
  getAvaliacaoRiscoField(state) {
    return getField(state.avaliacaoRisco);
  }
};

// mutations
const mutations = {
  updateField,
  updateAvaliacaoRiscoField(state, field) {
    updateField(state.avaliacaoRisco, field);
  },
  addParticipante(state, participante) {
    addToList(state.avaliacaoRisco.participantes, participante);
  },
  removeParticipante(state, participante) {
    state.avaliacaoRisco.participantes = removeFromList(state.avaliacaoRisco.participantes, participante);
  },
  updatePrestacaoField(state, input) {
    const prestacao = state.avaliacaoRisco.autoFinanciamento.prestacoes.find(r => r.numero === input.numero);
    prestacao[input.key] = input.value;
  }
};

// actions
const actions = {
  addParticipante({ commit }, participante) {
    return new Promise(resolve => {
      commit("addParticipante", participante);
      resolve(participante);
    });
  },
  removeParticipante({ commit }, participante) {
    return new Promise(resolve => {
      commit("removeParticipante", participante);
      resolve();
    });
  },
  newAvaliacaoRisco({ commit, state }) {
    return new Promise(resolve => {
      commit("updateField", { path: "avaliacaoRisco", value: _.cloneDeep(avaliacaoRiscoEmpty) });
      resolve(state.avaliacaoRisco);
    });
  },
  setAvaliacaoRisco({ commit, state }, avaliacaoRisco) {
    return new Promise(resolve => {
      commit("updateField", {
        path: "avaliacaoRisco",
        value: _.merge(_.cloneDeep(avaliacaoRiscoEmpty), avaliacaoRisco)
      });
      resolve(state.avaliacaoRisco);
    });
  }
};

// EXPORTS
export const { mapFields: mapAvaliacaoRiscoFields } = createHelpers({
  getterType: "caixaquiAvaliacaoRisco/getAvaliacaoRiscoField",
  mutationType: "caixaquiAvaliacaoRisco/updateAvaliacaoRiscoField"
});

// EXPORTS
export const { mapFields: mapAvaliacaoRisco } = createHelpers({
  getterType: "caixaquiAvaliacaoRisco/getField"
});

// FUNCTIONS
function addToList(list, value) {
  if (!value.uid) {
    list.push({ uid: ulid(), ...value });
  } else {
    const item = list.find(r => r.uid === value.uid);
    Object.assign(item, value);
  }
}

function removeFromList(list, value) {
  return _.filter(list, r => r.uid !== value.uid);
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
