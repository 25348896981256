/* eslint-disable max-len */
import ROUTERS from "./router-constants";
import caixaAquiCadastroClientesRouter from "./caixaqui-cadastro-clientes";
import caixaAquiAvaliacaoRiscoRouter from "./caixaqui-avaliacao-risco";

const caixaAquiRouter = [
  {
    // CAIXA AQUI ROOT
    path: ROUTERS.caixaqui.path,
    name: ROUTERS.caixaqui.name,
    component: () => import(/* webpackChunkName: "caixaqui" */ "../views/caixaqui/CaixaquiMain"),
    children: [
      {
        // MENUS
        path: ROUTERS.caixaqui.menus.path,
        name: ROUTERS.caixaqui.menus.name,
        component: () =>
          import(/* webpackChunkName: "caixaqui.menus.main" */ "../views/caixaqui/menus/CaixaquiMenuMain"),
        children: [
          {
            // MENU -> PRINCIPAL
            path: ROUTERS.caixaqui.menus.principal.path,
            name: ROUTERS.caixaqui.menus.principal.name,
            component: () =>
              import(/* webpackChunkName: "caixaqui.menus.principal" */ "../views/caixaqui/menus/CaixaquiMenuPrincipal")
          },
          {
            // MENU -> SERVICO AO CLIENTE
            path: ROUTERS.caixaqui.menus.servicoCliente.path,
            name: ROUTERS.caixaqui.menus.servicoCliente.name,
            component: () =>
              import(
                /* webpackChunkName: "caixaqui.menus.servico-cliente" */ "../views/caixaqui/menus/CaixaquiMenuServicoCliente"
              )
          },
          {
            // MENU -> NEGOCIOS
            path: ROUTERS.caixaqui.menus.negocios.path,
            name: ROUTERS.caixaqui.menus.negocios.name,
            component: () =>
              import(/* webpackChunkName: "caixaqui.menus.negocios" */ "../views/caixaqui/menus/CaixaquiMenuNegocios")
          }
        ]
      },
      ...caixaAquiCadastroClientesRouter,
      ...caixaAquiAvaliacaoRiscoRouter
    ]
  }
];

export default caixaAquiRouter;
