import { createHelpers, getField, updateField } from "vuex-map-fields";
import Vue from "vue";
import _ from "lodash";

// Ref:
//   - https://markus.oberlehner.net/blog/how-to-structure-a-complex-vuex-store/
//   - https://github.com/maoberlehner/vuex-map-fields#vuex-map-fields
//   - https://github.com/robinvdvleuten/vuex-persistedstate

const emptyDossie = {
  id: null,
  nome: null,
  status: "RASCUNHO",
  clientes: [],
  avaliacaoRisco: {
    cpf: null
  }
};

// initial state
const state = {
  dossieNames: [],
  dossies: [],
  currentDossie: _.cloneDeep(emptyDossie),
  loading: false
};

// getters
const getters = {
  getClienteCurrentDossie: state => cpf => {
    return state.currentDossie.clientes.find(r => r.cpf === cpf);
  },
  getField,
  getCurrentDossieField(state) {
    return getField(state.currentDossie);
  }
};

// mutations
const mutations = {
  updateField,
  updateCurrentDossieField(state, field) {
    updateField(state.currentDossie, field);
  },
  addDossie(state, data) {
    state.dossies.push(data);
  },
  addOrUpdateCliente(state, data) {
    if (!_.has(state.currentDossie, "clientes") || _.isNil(state.currentDossie.clientes)) {
      state.currentDossie.clientes = [];
    }

    if (data) {
      const item = state.currentDossie.clientes.find(r => r.cpf === data.cpf);
      if (item) {
        Vue.$log.debug("Updating: ", item);
        Object.assign(item, data);
      } else {
        Vue.$log.debug("Pushing: ", data);
        state.currentDossie.clientes.push(data);
      }
    }
  },
  removeClient(state, data) {
    if (data) {
      state.currentDossie.clientes = _.filter(state.currentDossie.clientes, r => r.cpf !== data.cpf);
    }
  },
  addOrUpdateAvaliacaoRisco(state, data) {
    if (data) {
      state.currentDossie.avaliacaoRisco = data;
    }
  }
};

// actions
const actions = {
  resetCurrentDossie({ commit }) {
    commit("updateField", { path: "currentDossie", value: _.cloneDeep(emptyDossie) });
  },
  saveCurrentDossie({ commit, state }) {
    return new Promise((resolve, reject) => {
      Vue.$axios().then(axios => {
        axios
          .post("/dossies", state.currentDossie)
          .then(value => {
            // commit("addDossie", value);
            // dispatch("resetCurrentDossie");
            commit("updateField", { path: "currentDossie", value: value.data.data });
            resolve(value);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  },
  deleteDossie({ commit }, dossieId) {
    commit("updateField", { path: "loading", value: true });
    return new Promise((resolve, reject) => {
      Vue.$axios().then(axios => {
        axios
          .delete(`/dossies/${dossieId}`)
          .then(value => {
            resolve(value);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            commit("updateField", { path: "loading", value: false });
          });
      });
    });
  },
  deleteAllDossie({ commit }, ids) {
    commit("updateField", { path: "loading", value: true });
    
    const params = {};
    params.ids = ids;
    return new Promise((resolve, reject) => {
      Vue.$axios().then(axios => {
        axios
          .post("/dossies/deleteAll", "", { params: params })
          .then(value => {
            resolve(value);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            commit("updateField", { path: "loading", value: false });
          });
      });
    });
  },
  async loadDossies({ commit }) {
    commit("updateField", { path: "loading", value: true });

    const axios = await Vue.$axios();
    axios
      .get("/dossies")
      .then(value => {
        commit("updateField", { path: "dossies", value: value.data.data });
      })
      .finally(() => {
        commit("updateField", { path: "loading", value: false });
      });
  },
  async loadDossie({ commit }, id) {
    commit("updateField", { path: "loading", value: true });

    const axios = await Vue.$axios();
    axios
      .get(`/dossies/${id}`)
      .then(value => {
        commit("updateField", { path: "currentDossie", value: value.data.data });
      })
      .finally(() => {
        commit("updateField", { path: "loading", value: false });
      });
  },
  addOrUpdateCliente({ commit }, item) {
    commit("addOrUpdateCliente", item);
  },
  addOrUpdateClienteAndSave({ commit, dispatch }, cliente) {
    return new Promise((resolve, reject) => {
      commit("addOrUpdateCliente", cliente);
      dispatch("saveCurrentDossie")
        .then(value => resolve(value))
        .catch(error => reject(error));
    });
  },
  removeClient({ commit }, item) {
    commit("removeClient", item);
  },
  addOrUpdateAvaliacaoRisco({ commit }, item) {
    commit("addOrUpdateAvaliacaoRisco", item);
  },
  addOrUpdateAvaliacaoRiscoAndSave({ commit, dispatch }, avaliacaoRisco) {
    return new Promise((resolve, reject) => {
      commit("addOrUpdateAvaliacaoRisco", avaliacaoRisco);
      dispatch("saveCurrentDossie")
        .then(value => resolve(value))
        .catch(error => reject(error));
    });
  },
  async loadDossiesNames({ commit }) {
    const axios = await Vue.$axios(false);
    axios.get("/dossies?fields=nome").then(value => {
      commit("updateField", { path: "dossieNames", value: value.data.data });
    });
  },
  uploadDossies({ commit }, file) {
    commit("updateField", { path: "loading", value: true });
    return new Promise((resolve, reject) => {
      Vue.$axios().then(axios => {
        const formData = new FormData();
        formData.append("file", file);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };

        axios
          .post("/dossies/upload", formData, config)
          .then(value => {
            resolve(value);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            commit("updateField", { path: "loading", value: false });
          });
      });
    });
  }
};

// EXPORTS
export const { mapFields: mapCurrentDossieFields } = createHelpers({
  getterType: "dossie/getCurrentDossieField",
  mutationType: "dossie/updateCurrentDossieField"
});

// EXPORTS
export const { mapFields: mapDossieFields } = createHelpers({
  getterType: "dossie/getField",
  mutationType: "dossie/updateField"
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
