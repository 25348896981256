const tiposComprovantesRenda = [
  "",
  "CARTEIRA DE TRABALHO (CTPS)",
  "COMPROVANTE DE RECEBIMENTO NO EXTERIOR",
  "CONTRACHEQUE/HOLLERITH",
  "CONTRATO DE ALUGUEL",
  "CONTRATO DE ESTÁGIO",
  "CONTRATO DE PRESTAÇÃO DE SERVIÇOS",
  "CONTRIBUIÇÃO DE INSS",
  "DECLARAÇÃO DE ASSOCIAÇÃO/COOPERATIVA/SINDICATO",
  "DECLARAÇÃO DE IMPOSTO DE RENDA",
  "DECLARAÇÃO EMPREGADOR/PREVIDENCIÁRIA",
  "DECORE",
  "DECORE C/ DARF",
  "EXTRATO APLICAÇÃO FINANCEIRA / POUPANÇA",
  "EXTRATO DE BENEFÍCIO PREVIDENCIÁRIO",
  "EXTRATO DE CONTA SALÁRIO/APOSENTADORIA",
  "EXTRATO DE FGTS",
  "NOTAS FISCAIS DE VENDAS (ATIVIDADE RURAL)",
  "RECIBO PAGAMENTO AUTONOMO (RPA)",
  "RECOLHIMENTO ISS",
  "SENTENÇA JUDICIAL"
];

export default tiposComprovantesRenda;
