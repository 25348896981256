const state = {
  authenticated: false,
  isAdmin: false,
  isProfessor: false,
  bearerToken: null,
  profile: {
    roles: []
  }
};

// getters
const getters = {
  roles: state => {
    return state.profile.roles;
  },
  isAuthenticated: state => {
    return state.authenticated;
  },
  isAdmin: state => {
    return state.isAdmin;
  },
  isProfessor: state => {
    return state.isProfessor;
  },
  profile: state => {
    return state.profile;
  }
};

// mutations
const mutations = {
  setLoginData(state, data) {
    state.authenticated = data.loggedIn;
    state.isAdmin = data.isAdmin;
    state.isProfessor = data.isProfessor;
    state.bearerToken = data.bearerToken;
    state.profile = data.profile;
  }
};

// actions
const actions = {
  handleLogin({ commit }, data) {
    if (data.loggedIn) {
      commit("setLoginData", data);
    } else {
      commit("setLoginData", {
        ...data,
        isAdmin: false,
        isProfessor: false,
        bearerToken: null,
        profile: {
          roles: []
        }
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
