import Vue from "vue";
import Router from "vue-router";
import publicRouter from "./public";
import privateRouter from "./private";
import auth0 from "../api/auth0";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...publicRouter, ...privateRouter]
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (auth0.isAuthenticated()) {
      if (to.meta.requiresAdminRole) {
        if (auth0.isAdmin()) {
          return next();
        } else {
          Vue.$log.debug("Not Admin");
          auth0.logOut("403");
        }
      } else if (to.meta.requiresProfessorRole) {
        if (auth0.isProfessor()) {
          return next();
        } else {
          Vue.$log.debug("Not Admin");
          auth0.logOut("403");
        }
      } else if (auth0.hasAnyRequiredRole()) {
        return next();
      } else {
        auth0.logOut("403");
      }
    } else {
      auth0.login({ target: to.fullPath });
    }
  } else {
    return next();
  }
});

export default router;
