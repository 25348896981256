import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import { CONFIG } from "../config";
import dadosGerais from "./modules/dados-gerais";
import dadosRepostaAssino from "./modules/dados-resposta-assino";
import dossie from "./modules/dossie";
import caixaquiClientes from "./modules/caixaqui-clientes";
import caixaquiAvaliacaoRisco from "./modules/caixaqui-avaliacao-risco";
import auth from "./modules/auth";
import VuexPersistence from "vuex-persist";
import menu from "./modules/menu";
import createCache from "vuex-cache";
import treinamento from "./modules/treinamento";
import respostaAssino from "./modules/assino-resposta-assino";
import controller from "./modules/controller";
import reports from "./modules/reports";
import formulariosEnviados from "./modules/formularios-enviados";

Vue.use(Vuex);

const debug = !CONFIG.isProd;
const timeoutProd = 60 * 1000; // 1 min
const timeoutDev = 10 * 1000; // 10 sec

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["caixaquiClientes", "caixaquiAvaliacaoRisco", "controller", "dossie", "treinamento", "formulariosEnviados", "respostaAssino"]
});

const store = new Vuex.Store({
  modules: {
    auth,
    caixaquiAvaliacaoRisco,
    caixaquiClientes,
    controller,
    dadosGerais,
    dadosRepostaAssino,
    dossie,
    menu,
    reports,
    treinamento,
    formulariosEnviados,
    respostaAssino
  },
  strict: debug,
  plugins: debug
    ? [createLogger(), vuexLocal.plugin, createCache({ timeout: timeoutDev })]
    : [createCache({ timeout: timeoutProd })]
});

export default store;
