<template>
  <div class="spinner">
    <img src="../assets/loading.svg" alt="Loading" />
  </div>
</template>

<script>
import Vue from "vue";
import auth0 from "../api/auth0";

export default {
  methods: {
    handleLoginEvent(data) {
      this.$log.info(data);
      if (!data.error) {
        this.$router.push(data.state.target);
      }
    }
  },
  async created() {
    try {
      await auth0.handleAuthentication();
    } catch (e) {
      await this.$router.push("/");
      Vue.$log.error(e);
    }
  }
};
</script>

<style scoped>
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
