var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      attrs: {
        "clipped-left": _vm.clippedLeft,
        app: "",
        dark: "",
        color: _vm.color
      }
    },
    [
      _c("v-app-bar-nav-icon", {
        on: {
          click: function($event) {
            return _vm.sideMenuClick(_vm.opened)
          }
        }
      }),
      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticClass: "flex-grow-1" }),
      _c(
        "v-btn",
        { staticClass: "mr-2", attrs: { icon: "", to: _vm.routes.home.path } },
        [_c("v-icon", [_vm._v("fa-home")])],
        1
      ),
      !_vm.isAuthenticated
        ? _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { text: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.login()
                }
              }
            },
            [_c("span", [_vm._v("Login")])]
          )
        : _vm._e(),
      _vm.isAuthenticated
        ? _c(
            "v-menu",
            {
              attrs: { dark: "", "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function({ on: menu }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on: tooltip }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          { attrs: { dark: "", icon: "" } },
                                          { ...tooltip, ...menu }
                                        ),
                                        [
                                          _c(
                                            "v-avatar",
                                            { attrs: { size: "36px" } },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.profile.picture
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.profile.name))])]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3377969763
              )
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { to: _vm.isProd ? "" : _vm.routes.profile.path }
                    },
                    [
                      _c(
                        "v-list-item-avatar",
                        [_c("v-img", { attrs: { src: _vm.profile.picture } })],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(_vm.profile.name) }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("fa-sign-out-alt")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Logout")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }