import { createHelpers, getField, updateField } from "vuex-map-fields";
import Vue from "vue";
import _ from "lodash";
import qs from "qs";

// Ref:
//   - https://markus.oberlehner.net/blog/how-to-structure-a-complex-vuex-store/
//   - https://github.com/maoberlehner/vuex-map-fields#vuex-map-fields
//   - https://github.com/robinvdvleuten/vuex-persistedstate

export const emptyTreinamento = {
  id: null,
  dossie: { id: null, nome: null },
  status: null,
  clientes: [],
  avaliacaoRisco: {
    cpf: null
  },
  respostaAssino: {
    cpfProponente1: null
  },
  createdBy: {
    email: null,
    name: null
  },
  completedAt: null,
  criticalErrorsLabel: null,
  nonCriticalErrorsLabel: null
};

// initial state
const state = {
  treinamentos: [],
  totalGrade: 0,
  currentTreinamento: _.cloneDeep(emptyTreinamento),
  loading: false
};

// getters
const getters = {
  currentTreinamento(state) {
    return state.currentTreinamento;
  },
  getField,
  getCurrentTreinamentoField(state) {
    return getField(state.currentTreinamento);
  },
  getClienteCurrentTreinamento: state => cpf => {
    return state.currentTreinamento.clientes.find(r => r.cpf === cpf);
  }
};

// mutations
const mutations = {
  currentTreinamento(state, treinamento) {
    state.currentTreinamento = _.merge(_.cloneDeep(emptyTreinamento), treinamento);
  },
  updateField,
  updateCurrentTreinamentoField(state, field) {
    updateField(state.currentTreinamento, field);
  },
  addTreinamento(state, data) {
    state.treinamentos.push(data);
  },
  addOrUpdateCliente(state, data) {
    if (!_.has(state.currentTreinamento, "clientes") || _.isNil(state.currentTreinamento.clientes)) {
      state.currentTreinamento.clientes = [];
    }

    if (data) {
      const item = state.currentTreinamento.clientes.find(r => r.cpf === data.cpf);
      if (item) {
        Vue.$log.debug("Updating: ", item);
        Object.assign(item, data);
      } else {
        Vue.$log.debug("Pushing: ", data);
        state.currentTreinamento.clientes.push(data);
      }
    }
  },
  removeClient(state, data) {
    if (data) {
      state.currentTreinamento.clientes = _.filter(state.currentTreinamento.clientes, r => r.cpf !== data.cpf);
    }
  },
  addOrUpdateAvaliacaoRisco(state, data) {
    if (data) {
      state.currentTreinamento.avaliacaoRisco = data;
    }
  },
  addOrUpdateRespostaAssino(state, data) {
    if (data) {
      state.currentTreinamento.respostaAssino = data;
    }
  }
};

// actions
const actions = {
  resetCurrentTreinamento({ commit }) {
    commit("updateField", { path: "currentTreinamento", value: _.cloneDeep(emptyTreinamento) });
  },
  createAndSaveNewTreinamento({ commit, dispatch }, nomeDossie) {
    return new Promise((resolve, reject) => {
      dispatch("resetCurrentTreinamento").then(() => {
        commit("updateField", { path: "currentTreinamento.dossie.nome", value: nomeDossie });
        dispatch("saveCurrentTreinamento")
          .then(value => resolve(value))
          .catch(error => reject(error));
      });
    });
  },
  saveCurrentTreinamento({ commit, state }) {
    console.log("olha o estado pai", state)
    return new Promise((resolve, reject) => {
      Vue.$axios().then(axios => {
        axios
          .post("/treinamentos", state.currentTreinamento)
          .then(value => {
            commit("updateField", { path: "currentTreinamento", value: value.data.data });
            console.log("olha o estado data data", value.data.data)
            resolve(value);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  },
  concluirCurrentTreinamentoAndSave({ dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch("concluirCurrentTreinamento")
        .then(value => resolve(value))
        .catch(error => reject(error));
    });
  },
  concluirCurrentTreinamento({ commit, state }) {
    return new Promise((resolve, reject) => {
      Vue.$axios().then(axios => {
        axios
          .post("/treinamentos?action=concluir", state.currentTreinamento)
          .then(value => {
            commit("updateField", { path: "currentTreinamento", value: value.data.data });
            resolve(value);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  },
  async loadTreinamentos({ commit }, filter) {
    commit("updateField", { path: "loading", value: true });

    let options = {};

    if (!_.isNil(filter)) {
      options = {
        params: {
          ...filter
        },
        paramsSerializer: params => {
          return qs.stringify(params, { indices: false });
        }
      };
    }

    const axios = await Vue.$axios();
    axios
      .get("/treinamentos", options)
      .then(value => {
        commit("updateField", { path: "treinamentos", value: value.data.data });
      })
      .finally(() => {
        commit("updateField", { path: "loading", value: false });
      });
  },
  async loadTreinamento({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("updateField", { path: "loading", value: true });
      Vue.$axios().then(axios => {
        axios
          .get(`/treinamentos/${id}`)
          .then(value => resolve(value.data.data))
          .catch(error => reject(error))
          .finally(() => {
            commit("updateField", { path: "loading", value: false });
          });
      });
    });
  },
  /*async loadCurrentTreinamento({ commit }, id) {
    commit("updateField", { path: "loading", value: true });

    const axios = await Vue.$axios();
    axios
      .get(`/treinamentos/${id}`)
      .then(value => {
        commit("updateField", { path: "currentTreinamento", value: value.data.data });
      })
      .finally(() => {
        commit("updateField", { path: "loading", value: false });
      });
  },*/
  addOrUpdateCliente({ commit }, cliente) {
    commit("addOrUpdateCliente", cliente);
  },
  addOrUpdateClienteAndSave({ commit, dispatch }, cliente) {
    return new Promise((resolve, reject) => {
      commit("addOrUpdateCliente", cliente);
      dispatch("saveCurrentTreinamento")
        .then(value => resolve(value))
        .catch(error => reject(error));
    });
  },
  removeClient({ commit }, item) {
    commit("removeClient", item);
  },
  addOrUpdateAvaliacaoRisco({ commit }, avaliacaoRisco) {
    commit("addOrUpdateAvaliacaoRisco", avaliacaoRisco);
  },
  addOrUpdateAvaliacaoRiscoAndSave({ commit, dispatch }, avaliacaoRisco) {
    return new Promise((resolve, reject) => {
      commit("addOrUpdateAvaliacaoRisco", avaliacaoRisco);
      dispatch("saveCurrentTreinamento")
        .then(value => resolve(value))
        .catch(error => reject(error));
    });
  },
  addOrUpdateRespostaAssino({ commit }, respostaAssino) {
    commit("addOrUpdateRespostaAssino", respostaAssino);
  },
  addOrUpdateRespostaAssinoAndSave({ commit, dispatch }, respostaAssino) {
    console.log("PASSOU AQYU UAI", respostaAssino )
    return new Promise((resolve, reject) => {
      commit("addOrUpdateRespostaAssino", respostaAssino);
      dispatch("saveCurrentTreinamento")
        .then(value => resolve(value))
        .catch(error => reject(error));
    });
  },
  async loadTotalGrade({ commit }) {
    return new Promise((resolve, reject) => {
      commit("updateField", { path: "loading", value: true });
      Vue.$axios().then(axios => {
        axios
          .get(`/grades`)
          .then(value => {
            commit("updateField", { path: "totalGrade", value: value.data.data.totalGrade });
            resolve(value.data.data.totalGrade);
          })
          .catch(error => reject(error))
          .finally(() => {
            commit("updateField", { path: "loading", value: false });
          });
      });
    });
  },
  async loadTotalGradeByoPeriod({ commit }, filter) {
    return new Promise((resolve, reject) => {
      commit("updateField", { path: "loading", value: true });
      Vue.$axios().then(axios => {

        const params = {};
        params.from = filter.from;
        params.to = filter.to;

        axios
          .get(`/grades/period`, { params: params })
          .then(value => {
            commit("updateField", { path: "totalGrade", value: value.data.data.totalGrade });
            resolve(value.data.data.totalGrade);
          })
          .catch(error => reject(error))
          .finally(() => {
            commit("updateField", { path: "loading", value: false });
          });
      });
    });
  },
  // eslint-disable-next-line no-unused-vars
  saveReview({ context }, data) {
    return new Promise((resolve, reject) => {
      Vue.$axios().then(axios => {
        axios
          .post(`/treinamentos/${data.treinamentoId}/review`, data.review, {
            params: {
              action: data.finish ? "finalizar" : null
            }
          })
          .then(value => {
            resolve(value.data.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  }
};

// EXPORTS
export const { mapFields: mapCurrentTreinamentoFields } = createHelpers({
  getterType: "treinamento/getCurrentTreinamentoField",
  mutationType: "treinamento/updateCurrentTreinamentoField"
});

// EXPORTS
export const { mapFields: mapTreinamentoFields } = createHelpers({
  getterType: "treinamento/getField",
  mutationType: "treinamento/updateField"
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
