var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "mx-auto" }, [
      _c("span", { staticClass: "title ma-3" }, [
        _vm._v("Olá " + _vm._s(_vm.profile.name) + "!")
      ]),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap ma-3 justify-center" },
        _vm._l(_vm.menu, function(item, i) {
          return _c(
            "v-card",
            {
              key: i,
              staticClass: "text-center ma-2 menu",
              attrs: { "max-width": "200", outlined: "", tile: "", to: item.to }
            },
            [
              _c("v-icon", {
                staticClass: "mt-2",
                attrs: { "x-large": "" },
                domProps: { textContent: _vm._s(item.icon) }
              }),
              _c("v-card-title", { staticClass: "justify-center" }, [
                _vm._v("\n          " + _vm._s(item.title) + "\n        ")
              ])
            ],
            1
          )
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }