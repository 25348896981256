<template>
  <v-app-bar :clipped-left="clippedLeft" app dark :color="color">
    <v-app-bar-nav-icon v-on:click="sideMenuClick(opened)"></v-app-bar-nav-icon>
    <v-toolbar-title>{{ title }}</v-toolbar-title>

    <div class="flex-grow-1"></div>

    <v-btn icon :to="routes.home.path" class="mr-2">
      <v-icon>fa-home</v-icon>
    </v-btn>

    <v-btn class="mr-2" text @click.prevent="login()" v-if="!isAuthenticated">
      <span>Login</span>
    </v-btn>

    <v-menu dark offset-y v-if="isAuthenticated">
      <template v-slot:activator="{ on: menu }">
        <v-tooltip left>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn dark icon v-on="{ ...tooltip, ...menu }">
              <v-avatar size="36px">
                <img :src="profile.picture" />
              </v-avatar>
            </v-btn>
          </template>
          <span>{{ profile.name }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item :to="isProd ? '' : routes.profile.path">
          <v-list-item-avatar>
            <v-img :src="profile.picture"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="profile.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click.prevent="logout()">
          <v-list-item-icon>
            <v-icon>fa-sign-out-alt</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { CONFIG } from "../../config";
import ROUTERS from "../../routes/router-constants";
import auth0 from "../../api/auth0";

export default {
  name: "AppBar",
  props: ["title", "clippedLeft", "opened", "color"],
  data: () => ({
    isProd: CONFIG.isProd,
    routes: ROUTERS
  }),
  computed: {
    ...mapGetters("auth", {
      isAuthenticated: "isAuthenticated",
      isAdmin: "isAdmin",
      profile: "profile"
    })
  },
  methods: {
    login() {
      auth0.login();
    },
    logout() {
      auth0.logOut();
    },
    sideMenuClick(value) {
      this.$emit("side-menu-click", !value);
    }
  }
};
</script>

<style scoped></style>
