import { EventEmitter } from "events";

class Forward extends EventEmitter {}
const service = new Forward();
service.setMaxListeners(2);

export default {
  install(Vue) {
    Vue.prototype.$backForwardEvent = service;

    Vue.mixin({
      created() {
        if (this.handleForwardEvent) {
          Vue.$log.debug(`Registering onForward event for ${this.$options.name}`);
          service.addListener("onForward", this.handleForwardEvent);
        }

        if (this.handleBackwardEvent) {
          Vue.$log.debug(`Registering onBackward event for ${this.$options.name}`);
          service.addListener("onBackward", this.handleBackwardEvent);
        }
      },

      destroyed() {
        if (this.handleForwardEvent) {
          Vue.$log.debug(`Destroying onForward event for ${this.$options.name}`);
          service.removeListener("onForward", this.handleForwardEvent);
        }

        if (this.handleBackwardEvent) {
          Vue.$log.debug(`Destroying onBackward event for ${this.$options.name}`);
          service.removeListener("onBackward", this.handleBackwardEvent);
        }
      }
    });
  }
};
