import { createHelpers, getField, updateField } from "vuex-map-fields";
import _ from "lodash";
import { ulid } from "ulid";
// Ref:
//   - https://markus.oberlehner.net/blog/how-to-structure-a-complex-vuex-store/
//   - https://github.com/maoberlehner/vuex-map-fields#vuex-map-fields
//   - https://github.com/robinvdvleuten/vuex-persistedstate

export const respostaAssinoEmpty = Object.freeze({
  nomeProponente1: null,
  cpfProponente1: null,
  nomeProponente2: null,
  cpfProponente2: null,
  valorIMovel: null,
  valorFinanciamento: null,
  subsidio: null,
  fgts: null,
  prazoMeses: null,
  valorTaxaJuros: null,
  valorTotalProposta: null,
  valorPrestacao: null,
  rendaFamiliar: null,
  dataAvaliação: null,
  dataVencimento: null,
  observacoesPendencias: null,
  situacaoAnalise: null,
  possuiFGTS: null,
  possuiDireitoRedutorTaxa: null,
  enquadramento: null,
  sistemaAmortizacao: null,
  indexador: null
});

// initial state
const state = {
  respostaAssino: _.cloneDeep(respostaAssinoEmpty)
};

// getters
const getters = {
  respostaAssino(state) {
    return state.respostaAssino;
  },
  getField,
  getRespostaAssinoField(state) {
    return getField(state.respostaAssino);
  }
};

// mutations
const mutations = {
  updateField,
  updateRespostaAssinoField(state, field) {
    updateField(state.respostaAssino, field);
  }
};

// actions
const actions = {
  newRespostaAssino({ commit, state }) {
    return new Promise(resolve => {
      commit("updateField", { path: "respostaAssino", value: _.cloneDeep(respostaAssinoEmpty) });
      resolve(state.respostaAssino);
    });
  },
  setRespostaAssino({ commit, state }, respostaAssino) {
    return new Promise(resolve => {
      commit("updateField", {
        path: "respostaAssino",
        value: _.merge(_.cloneDeep(respostaAssinoEmpty), respostaAssino)
      });
      resolve(state.respostaAssino);
    });
  }
};

// EXPORTS
export const { mapFields: mapRespostaAssinoFields } = createHelpers({
  getterType: "respostaAssino/getRespostaAssinoField",
  mutationType: "respostaAssino/updateRespostaAssinoField"
});

// EXPORTS
export const { mapFields: mapRespostaAssino } = createHelpers({
  getterType: "respostaAssino/getField"
});


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
