import ROUTERS from "../../routes/router-constants";
import Vue from "vue";

const operationTypes = {
  CADASTRO_DOSSIE: "CADASTRO_DOSSIE",
  CADASTRO_TREINAMENTO: "CADASTRO_TREINAMENTO",
  VISUALIZAR_TREINAMENTO: "VISUALIZAR_TREINAMENTO"
};

const subOperationTypes = {
  CADASTRO_CLIENTE: "CADASTRO_CLIENTE",
  CADASTRO_AVALIACAO_RISCO: "CADASTRO_AVALIACAO_RISCO",
  CADASTRO_RESPOSTA_ASSINO: "CADASTRO_RESPOSTA_ASSINO",
};

const state = {
  operationType: null,
  subOperationType: null,
  sourceRoute: null
};

// getters
const getters = {
  operationType: state => {
    return state.operationType;
  },
  subOperationType: state => {
    return state.subOperationType;
  },
  sourceRoute: state => {
    return state.sourceRoute;
  },
  getMenuLink: state => originalMenuLink => {
    if (state.operationType === operationTypes.CADASTRO_DOSSIE) {
      return null;
    }
    return originalMenuLink;
  },
  getSairMenuLabel: state => {
    if (state.operationType === operationTypes.CADASTRO_DOSSIE) {
      return "Salvar Dossiê e Sair";
    }
    return "Sair";
  }
};

// mutations
const mutations = {
  setOperationType(state, operation) {
    state.operationType = operation;
  },
  setSubOperationType(state, subOperation) {
    state.subOperationType = subOperation;
  },
  setSourceRoute(state, route) {
    state.sourceRoute = route;
  }
};

// actions
const actions = {
  menuSair({ getters, commit, dispatch }, data) {
    if (getters.operationType === operationTypes.CADASTRO_DOSSIE) {
      if (getters.subOperationType === subOperationTypes.CADASTRO_CLIENTE) 
      {
        dispatch("dossieMenuSairCliente", data);
      } 
      else if (getters.subOperationType === subOperationTypes.CADASTRO_AVALIACAO_RISCO)
       {
        dispatch("dossieMenuSairAvaliacaoRisco", data);
      }
    } 
    else if (getters.operationType === operationTypes.CADASTRO_TREINAMENTO)
     {
      if (getters.subOperationType === subOperationTypes.CADASTRO_CLIENTE) {
        dispatch("treinamentoMenuSairCliente", data);
      } else if (getters.subOperationType === subOperationTypes.CADASTRO_AVALIACAO_RISCO) {
        dispatch("treinamentoMenuSairAvaliacaoRisco", data);
      }else if(getters.subOperationType === subOperationTypes.CADASTRO_RESPOSTA_ASSINO)
      {
        debugger
        dispatch("treinamentoMenuSairRespostaAssino", data);
      }
    } else {
      commit("treinamento/currentTreinamento", {}, { root: true });
      data.router.push(getters.sourceRoute);
    }
  },
  dossieMenuSairCliente({ getters, dispatch, rootGetters }, data) {
    const cliente = rootGetters["caixaquiClientes/cliente"];

    Vue.$alert.confirmation("Deseja salvar antes de sair?", "").then(result => {
      if (result.value) {
        dispatch("dossie/addOrUpdateClienteAndSave", cliente, { root: true }).then(() => {
          Promise.all([
            dispatch("caixaquiClientes/newCliente", null, { root: true }),
            dispatch("caixaquiClientes/setClientes", [], { root: true })
          ]).then(() => {
            Vue.$alert.notifySuccess("Salvo com sucesso!");
            data.router.push(getters.sourceRoute);
          });
        });
      } else {
        data.router.push(getters.sourceRoute);
      }
    });
  },
  dossieMenuSairAvaliacaoRisco({ getters, dispatch, rootGetters }, data) {
    const avaliacaoRisco = rootGetters["caixaquiAvaliacaoRisco/avaliacaoRisco"];

    Vue.$alert.confirmation("Deseja salvar antes de sair?", "").then(result => {
      if (result.value) {
        dispatch("dossie/addOrUpdateAvaliacaoRiscoAndSave", avaliacaoRisco, { root: true }).then(() => {
          dispatch("caixaquiAvaliacaoRisco/newAvaliacaoRisco", null, { root: true }).then(() => {
            Vue.$alert.notifySuccess("Salvo com sucesso!");
            data.router.push(getters.sourceRoute);
          });
        });
      } else {
        data.router.push(getters.sourceRoute);
      }
    });
  },
  dossieNovoCliente({ commit, dispatch }, data) {
    commit("setOperationType", operationTypes.CADASTRO_DOSSIE);
    commit("setSubOperationType", subOperationTypes.CADASTRO_CLIENTE);
    commit("setSourceRoute", data.sourceRoute);

    Promise.all([
      dispatch("caixaquiClientes/newCliente", null, { root: true }),
      dispatch("caixaquiClientes/setClientes", [], { root: true }),
      dispatch("caixaquiClientes/setReadOnlyDossie", null, { root: true })
    ]).then(() => {
      data.router.push({ name: ROUTERS.caixaqui.cadastroClientes.cpfCliente.name });
    });
  },
  dossieEditCliente({ commit, dispatch }, data) {
    commit("setOperationType", operationTypes.CADASTRO_DOSSIE);
    commit("setSubOperationType", subOperationTypes.CADASTRO_CLIENTE);
    commit("setSourceRoute", data.sourceRoute);

    Promise.all([
      dispatch("caixaquiClientes/setCliente", data.cliente, { root: true }),
      dispatch("caixaquiClientes/setClientes", [], { root: true }),
      dispatch("caixaquiClientes/setReadOnlyDossie", null, { root: true })
    ]).then(() => {
      data.router.push({ name: ROUTERS.caixaqui.cadastroClientes.cpfCliente.name });
    });
  },
  dossieNovaAvaliacaoRisco({ commit, dispatch }, data) {
    commit("setOperationType", operationTypes.CADASTRO_DOSSIE);
    commit("setSubOperationType", subOperationTypes.CADASTRO_AVALIACAO_RISCO);
    commit("setSourceRoute", data.sourceRoute);

    dispatch("caixaquiAvaliacaoRisco/newAvaliacaoRisco", null, { root: true }).then(() => {
      data.router.push({ name: ROUTERS.caixaqui.avaliacaoRiscos.dadosTomador.name });
    });
  },
  dossieEditAvaliacaoRisco({ commit, dispatch }, data) {
    commit("setOperationType", operationTypes.CADASTRO_DOSSIE);
    commit("setSubOperationType", subOperationTypes.CADASTRO_AVALIACAO_RISCO);
    commit("setSourceRoute", data.sourceRoute);

    dispatch("caixaquiAvaliacaoRisco/setAvaliacaoRisco", data.avaliacaoRisco, { root: true }).then(() => {
      data.router.push({ name: ROUTERS.caixaqui.avaliacaoRiscos.dadosTomador.name });
    });
  },
  dossieEditRespostaAssino({ commit, dispatch }, data) {
    commit("setOperationType", operationTypes.CADASTRO_DOSSIE);
    commit("setSubOperationType", subOperationTypes.CADASTRO_RESPOSTA_ASSINO);
    commit("setSourceRoute", data.sourceRoute);

    dispatch("respostaAssino/setRespostaAssino", data.respostaAssino, { root: true }).then(() => {
      data.router.push({ name: ROUTERS.assino.name });
    });
  },
  dossieFindCliente({ rootGetters }, cpf) {
    return new Promise(resolve => {
      const cliente = rootGetters["dossie/getClienteCurrentDossie"](cpf);
      resolve(cliente);
    });
  },
  concluirCadastroCliente({ getters, dispatch }) {
    return new Promise(resolve => {
      if (getters.operationType === operationTypes.CADASTRO_TREINAMENTO) {
        dispatch("treinamentoConcluirCadastroCliente").then(() => resolve());
      } else {
        resolve();
      }
    });
  },
  solicitarAvaliacaoRisco({ getters, dispatch }) {
    return new Promise(resolve => {
      if (getters.operationType === operationTypes.CADASTRO_TREINAMENTO) {
        dispatch("treinamentoSolicitarAvaliacaoRisco").then(() => resolve());
      } else {
        resolve();
      }
    });
  },
  solicitarRespostaAssino({ getters, dispatch }) {
    return new Promise(resolve => {
      if (getters.operationType === operationTypes.CADASTRO_TREINAMENTO) {
        dispatch("treinamentoSolicitarRespostaAssino").then(() => resolve());
      } else {
        resolve();
      }
    });
  },
  treinamentoMenuSairCliente({ getters, dispatch, rootGetters }, data) {
    const cliente = rootGetters["caixaquiClientes/cliente"];

    Vue.$alert.confirmation("Deseja salvar antes de sair?", "").then(result => {
      if (result.value) {
        dispatch("treinamento/addOrUpdateClienteAndSave", cliente, { root: true }).then(() => {
          Promise.all([
            dispatch("caixaquiClientes/newCliente", null, { root: true }),
            dispatch("caixaquiClientes/setClientes", [], { root: true })
          ]).then(() => {
            Vue.$alert.notifySuccess("Salvo com sucesso!");
            data.router.push(getters.sourceRoute);
          });
        });
      } else {
        data.router.push(getters.sourceRoute);
      }
    });
  },
  treinamentoMenuSairAvaliacaoRisco({ getters, dispatch, rootGetters }, data) {
    const avaliacaoRisco = rootGetters["caixaquiAvaliacaoRisco/avaliacaoRisco"];

    Vue.$alert.confirmation("Deseja salvar antes de sair?", "").then(result => {
      if (result.value) {
        dispatch("treinamento/addOrUpdateAvaliacaoRiscoAndSave", avaliacaoRisco, { root: true }).then(() => {
          dispatch("caixaquiAvaliacaoRisco/newAvaliacaoRisco", null, { root: true }).then(() => {
            Vue.$alert.notifySuccess("Salvo com sucesso!");
            data.router.push(getters.sourceRoute);
          });
        });
      } else {
        data.router.push(getters.sourceRoute);
      }
    });
  },
  treinamentoMenuSairRespostaAssino({ getters, dispatch, rootGetters }, data) {

    const respostaAssino = rootGetters["respostaAssino/respostaAssino"];
    Vue.$alert.confirmation("Deseja salvar a Resposta Assino?", "").then(result => {
      if (result.value) {
        dispatch("treinamento/addOrUpdateRespostaAssinoAndSave", respostaAssino, { root: true }).then(() => {
          dispatch("respostaAssino/newRespostaAssino", null, { root: true }).then(() => {
            Vue.$alert.notifySuccess("Salvo com sucesso!");
            data.router.push(getters.sourceRoute);
          });
        });
      } else {
        data.router.push(getters.sourceRoute);
      }
    });
  },
  treinamentoNovoCliente({ commit, dispatch, rootGetters }, data) {
    commit("setOperationType", operationTypes.CADASTRO_TREINAMENTO);
    commit("setSubOperationType", subOperationTypes.CADASTRO_CLIENTE);
    commit("setSourceRoute", data.sourceRoute);

    const currentTreinamento = rootGetters["treinamento/currentTreinamento"];

    Promise.all([
      dispatch("caixaquiClientes/newCliente", null, { root: true }),
      dispatch("caixaquiClientes/setClientes", currentTreinamento.clientes, { root: true }),
      dispatch("caixaquiClientes/setReadOnlyDossie", currentTreinamento.dossie, { root: true }),
      dispatch("caixaquiAvaliacaoRisco/setAvaliacaoRisco", currentTreinamento.avaliacaoRisco, { root: true }),
      dispatch("respostaAssino/setRespostaAssino", currentTreinamento.respostaAssino, { root: true })
    ]).then(() => {
      data.router.push({ name: ROUTERS.caixaqui.cadastroClientes.cpfCliente.name });
    });
  },
  treinamentoEditCliente({ commit, dispatch, rootGetters }, data) {
    commit("setOperationType", operationTypes.CADASTRO_TREINAMENTO);
    commit("setSubOperationType", subOperationTypes.CADASTRO_CLIENTE);
    commit("setSourceRoute", data.sourceRoute);

    const currentTreinamento = rootGetters["treinamento/currentTreinamento"];

    Promise.all([
      dispatch("caixaquiClientes/setCliente", data.cliente, { root: true }),
      dispatch("caixaquiClientes/setClientes", currentTreinamento.clientes, { root: true }),
      dispatch("caixaquiClientes/setReadOnlyDossie", currentTreinamento.dossie, { root: true }),
      dispatch("caixaquiAvaliacaoRisco/setAvaliacaoRisco", currentTreinamento.avaliacaoRisco, { root: true }),
      dispatch("respostaAssino/setRespostaAssino", currentTreinamento.respostaAssino, { root: true })
    ]).then(() => {
      data.router.push({ name: ROUTERS.caixaqui.cadastroClientes.cpfCliente.name });
    });
  },
  treinamentoNovaAvaliacaoRisco({ commit, dispatch }, data) {
    commit("setOperationType", operationTypes.CADASTRO_TREINAMENTO);
    commit("setSubOperationType", subOperationTypes.CADASTRO_AVALIACAO_RISCO);
    commit("setSourceRoute", data.sourceRoute);

    dispatch("caixaquiAvaliacaoRisco/newAvaliacaoRisco", null, { root: true }).then(() => {
      data.router.push({ name: ROUTERS.caixaqui.avaliacaoRiscos.dadosTomador.name });
    });
  },
  treinamentoEditAvaliacaoRisco({ commit, dispatch }, data) {
    commit("setOperationType", operationTypes.CADASTRO_TREINAMENTO);
    commit("setSubOperationType", subOperationTypes.CADASTRO_AVALIACAO_RISCO);
    commit("setSourceRoute", data.sourceRoute);

    dispatch("caixaquiAvaliacaoRisco/setAvaliacaoRisco", data.avaliacaoRisco, { root: true }).then(() => {
      data.router.push({ name: ROUTERS.caixaqui.avaliacaoRiscos.dadosTomador.name });
    });
  },
  treinamentoNovaRespostaAssino({ commit, dispatch }, data) {
    commit("setOperationType", operationTypes.CADASTRO_TREINAMENTO);
    commit("setSubOperationType", subOperationTypes.CADASTRO_RESPOSTA_ASSINO);
    commit("setSourceRoute", data.sourceRoute);

    dispatch("respostaAssino/newRespostaAssino", null, { root: true }).then(() => {
      data.router.push({ name: ROUTERS.assino.name });
    });
  },
  treinamentoEditRespostaAssino({ commit, dispatch }, data) {
    commit("setOperationType", operationTypes.CADASTRO_TREINAMENTO);
    commit("setSubOperationType", subOperationTypes.CADASTRO_RESPOSTA_ASSINO);
    commit("setSourceRoute", data.sourceRoute);

    dispatch("respostaAssino/setRespostaAssino", data.respostaAssino, { root: true }).then(() => {
      data.router.push({ name: ROUTERS.assino.name });
    });
  },
  treinamentoConcluirCadastroCliente({ dispatch, rootGetters }) {
    return new Promise(resolve => {
      const cliente = rootGetters["caixaquiClientes/cliente"];
      dispatch("treinamento/addOrUpdateClienteAndSave", cliente, { root: true }).then(() => {
        const clientes = rootGetters["treinamento/currentTreinamento"].clientes;
        Promise.all([dispatch("caixaquiClientes/setClientes", clientes, { root: true })]).then(() => resolve());
      });
    });
  },
  treinamentoFindCliente({ rootGetters }, cpf) {
    return new Promise(resolve => {
      const cliente = rootGetters["treinamento/getClienteCurrentTreinamento"](cpf);
      resolve(cliente);
    });
  },
  treinamentoSolicitarAvaliacaoRisco({ dispatch, rootGetters }) {
    return new Promise(resolve => {
      const avaliacaoRisco = rootGetters["caixaquiAvaliacaoRisco/avaliacaoRisco"];
      dispatch("treinamento/addOrUpdateAvaliacaoRisco", avaliacaoRisco, { root: true }).then(() => {
        const ar = rootGetters["treinamento/currentTreinamento"].avaliacaoRisco;
        Promise.all([dispatch("caixaquiAvaliacaoRisco/setAvaliacaoRisco", ar, { root: true })]).then(() => resolve());
      });
    });
  },
  treinamentoSolicitarRespostaAssino({ dispatch, rootGetters }) {
    return new Promise(resolve => {
      const respostaAssino = rootGetters["respostaAssino/respostaAssino"];
      console.log("treinamento/addOrUpdateRespostaAssino", respostaAssino)
      dispatch("treinamento/addOrUpdateRespostaAssino", respostaAssino, { root: true }).then(() => {
        const respostaAssino = rootGetters["treinamento/currentTreinamento"].respostaAssino;
        Promise.all([dispatch("respostaAssino/setRespostaAssino", respostaAssino, { root: true })]).then(() => resolve());
      });
    });
  },
  concluirTreinamento({ dispatch, rootGetters }) {
    return new Promise(resolve => {
      const currentTreinamento = rootGetters["treinamento/currentTreinamento"];

      dispatch("treinamento/concluirCurrentTreinamentoAndSave", currentTreinamento, { root: true }).then(() => {
        resolve();
      });
    });
  },
  findCliente({ getters, dispatch }, cpf) {
    return new Promise(resolve => {
      if (getters.operationType === operationTypes.CADASTRO_TREINAMENTO) {
        dispatch("treinamentoFindCliente", cpf).then(cliente => resolve(cliente));
      } else if (getters.operationType === operationTypes.CADASTRO_DOSSIE) {
        dispatch("dossieFindCliente", cpf).then(cliente => resolve(cliente));
      } else {
        resolve();
      }
    });
  }
};

export { operationTypes };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
