<template>
  <v-container class="d-flex">
    <div class="mx-auto">
      <span class="title ma-3">Olá {{ profile.name }}!</span>
      <div class="d-flex flex-wrap ma-3 justify-center">
        <v-card
          :key="i"
          class="text-center ma-2 menu"
          max-width="200"
          outlined
          tile
          v-for="(item, i) in menu"
          :to="item.to"
        >
          <v-icon class="mt-2" v-text="item.icon" x-large></v-icon>

          <v-card-title class="justify-center">
            {{ item.title }}
          </v-card-title>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import ROUTERS from "../routes/router-constants";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  data: () => ({
    routers: ROUTERS
  }),
  computed: {
    ...mapGetters("menu", ["menuItemsByRoles"]),
    ...mapGetters("auth", ["roles", "profile"]),
    menu() {
      return this.menuItemsByRoles(this.roles).filter(value => value.title.toUpperCase() !== "HOME");
    }
  }
};
</script>

<style scoped>
.menu {
  width: 250px;
}
</style>
