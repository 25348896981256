var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c("SideMenu", {
        staticClass: "d-print-none",
        attrs: {
          clipped: _vm.$vuetify.breakpoint.lgAndUp,
          opened: _vm.sideMenuOpened
        },
        on: { "side-menu-click": _vm.sideMenuClicked }
      }),
      _c("AppBar", {
        staticClass: "d-print-none",
        attrs: {
          color: _vm.color,
          opened: _vm.sideMenuOpened,
          "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
          title: _vm.title
        },
        on: { "side-menu-click": _vm.sideMenuClicked }
      }),
      _c(
        "v-main",
        [
          _c("error"),
          _c("v-container", { attrs: { fluid: "" } }, [_c("router-view")], 1)
        ],
        1
      ),
      _c(
        "v-footer",
        { staticClass: "d-print-none", attrs: { color: _vm.color, app: "" } },
        [
          _c("v-spacer"),
          _c("span", { staticClass: "white--text mr-3" }, [
            _vm._v(
              _vm._s(_vm.version) + " - © " + _vm._s(new Date().getFullYear())
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }